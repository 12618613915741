import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TaskCategory } from '../models/taskcategory';

@Injectable({
  providedIn: 'root'
})
export class EvoTaskCategoryService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<TaskCategory[]>{
    return this.httpClient.get<TaskCategory[]>(this.baseApiUrl+'GetTaskCategoryList');
  }
  get (id: number):Observable <TaskCategory> {
    return this.httpClient.get<TaskCategory>(this.baseApiUrl+'GetTaskCategory/'+id);
  }
  add(taskCategory:TaskCategory):Observable<TaskCategory>{
    return this.httpClient.post<TaskCategory>(this.baseApiUrl+'AddTaskCategory/',taskCategory);
  }
  update(taskCategory:TaskCategory):Observable<TaskCategory>{
    return this.httpClient.post<TaskCategory>(this.baseApiUrl+"UpdateTaskCategory/",taskCategory);
  }
  delete(taskCategory:TaskCategory):Observable<TaskCategory>{
    return this.httpClient.post<TaskCategory>(this.baseApiUrl+"DeleteTaskCategory",taskCategory);
  }
}
