import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { CvPoolItem } from 'app/evo/models/cv-pool-item';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoCvPoolItemService } from 'app/evo/services/evo-cv-pool-item.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

const themes = {
    light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-indigo/theme.css',
    dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-indigo/theme.css'
  };


@Component({
  selector: 'app-evo-cv-pool-itemlist',
  templateUrl: './evo-cv-pool-item-list.component.html',
  styles: [
  ],

})
export class EvoCvPoolItemListComponent implements OnInit {

  constructor(private cvPoolItemService:EvoCvPoolItemService,
    private errorService:ErrorService,
    private helperService:HelperService,
    private informationService:InformationService,
    private decodeService:DecodeService,
    private router: Router,
    private cdr:ChangeDetectorRef,
    ) { }

  public basicSelectedOption: number = 10;

  cvPoolItemList:CvPoolItem[]=[];
  filteredCvPoolItemList:CvPoolItem[]=[];
  cvPoolItem:CvPoolItem = new CvPoolItem();
  filterText: string = "";

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;
  private primeflexLink: HTMLLinkElement;

  previousBackgroundColor:string="default";
  backgroundColor:string="default";
  userTypes:any[]=[{name:"Personel",value:1},{name:"Stajyer",value:2}]
  selectedUserTypes:number[]=[];

  workplacePositions:any[]=[
    {name:"Yapay Zeka",value:1},
    {name:"Mobil",value:2},
    {name:"Frontend", value:3},
    {name:"Backend",value:4},
    {name:"Fullstack",value:5}
]

  selectedWorkplacePositions:number[]=[];


  deletedDeparmentId:number;

  ngOnInit(): void {

    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();


    let item=this.decodeService.getRole();
    if(item!="Admin"){
      this.router.navigate(['/home']);
    }
    else{
      this.list();
    }
  }

  tableByFiltersId(){
    let filteredItems = this.cvPoolItemList as any;

    console.log(filteredItems);
    if (this.selectedUserTypes.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedUserTypes.includes(item.applierTypeId));
    }

    if (this.selectedWorkplacePositions.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedWorkplacePositions.includes(item.workplacePositionId));
    }


    this.filteredCvPoolItemList = filteredItems;
  }


  get(id:number){
    this.cvPoolItemService.get(id).subscribe((res:any)=>{
        this.cvPoolItem = res.data;
    })
  }

  list() {
    this.cvPoolItemService.getList().subscribe((items: any) => {
      this.cvPoolItemList = items.data;
      this.tableByFiltersId();
      this.informationService.list(items.message)
    },(err)=>{
      this.errorService.errorHandler(err);
    });
  }

  deleteCvItem() {
    this.cvPoolItemService.delete(this.cvPoolItem).subscribe((item: any) => {
    this.informationService.delete(item.message)
  },(err)=>{
    this.errorService.errorHandler(err);
  },()=>{
    setTimeout(() => {
      this.list();
    }, 1500);
  });
  }
  
  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Cv Havuzu";
    this.helperService.exportExcel(element, title);
  }

  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-indigo/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);

    // PrimeFlex (Added here)
    this.primeflexLink = document.createElement('link');
    this.primeflexLink.rel = 'stylesheet';
    this.primeflexLink.href = 'https://cdn.jsdelivr.net/npm/primeflex@3.3.0/primeflex.min.css';
    head.appendChild(this.primeflexLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
    if (this.primeflexLink) {  // Ensure PrimeFlex is also removed
        this.primeflexLink.remove();
    }
  }

  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
  }

  listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cdr.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }


}
