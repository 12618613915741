import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { PartnerUser } from 'app/evo/models/partneruser';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-customer-user-add-form',
  templateUrl: './evo-customer-user-add-form.component.html',
  styles: [
  ]
})
export class EvoPartnerUserAddFormComponent implements OnInit {

  constructor(
    private partnerUserService: EvoPartnerUserService,
    private partnerService:EvoPartnerService,
    private userService:EvoUserService,
    private router: Router,
    private informationService: InformationService,
    private decodeService:DecodeService,
    private errorService: ErrorService
  ) { }

  partnerUser: PartnerUser = new PartnerUser();
  user:User=new User();
  partner:Partner=new  Partner();
  myId:number;
  users:User[]=[];
  partners:Partner[]=[];
  
  ngOnInit(): void {
    this.myId=this.decodeService.getUserId();
    this.partnerService.getList().subscribe((res:any)=>{
      this.partners=res.data;
    });
    this.userService.getList().subscribe((res:any)=>{
      this.users=res.data;
    });
  }

  add() {

    this.partnerUser={
      id:0,
      partnerId:this.partner.id,
      partnerName:this.partner.name,
      userId:0,
      userName:"",
      firstName:"",
      lastName:"",
      mailAddress:"",
      phoneNumber:"",
      password:"",
      title:"",
      genderId:1,
      imageUrl:"",
      roleId:2,
      isActive:true,
      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    }

    this.partnerUserService.add(this.partnerUser).subscribe((item:any)=>{
      this.informationService.add(item.message);
  
    },(err)=>{
      this.errorService.errorHandler(err);
    },()=>{
    setTimeout(() => {
      this.router.navigate(["/home/partnerusers"]);
    }, 1500);
    });
  }
}
