import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CvPoolItem } from '../models/cv-pool-item';

@Injectable({
  providedIn: 'root'
})
export class EvoCvPoolItemService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<CvPoolItem[]>{
    return this.httpClient.get<CvPoolItem[]>(this.baseApiUrl+'GetCvPoolItemList');
  }
  get (id: number):Observable <CvPoolItem> {
    return this.httpClient.get<CvPoolItem>(this.baseApiUrl+'GetCvPoolItem/'+id);
  }
  add(CvPoolItem:FormData):Observable<CvPoolItem>{
    return this.httpClient.post<CvPoolItem>(this.baseApiUrl+'AddCvPoolItem/',CvPoolItem);
  }

  update(CvPoolItem:FormData):Observable<CvPoolItem>{
    return this.httpClient.post<CvPoolItem>(this.baseApiUrl+'UpdateCvPoolItem/',CvPoolItem);
  }
  delete(CvPoolItem:CvPoolItem):Observable<CvPoolItem>{
    return this.httpClient.post<CvPoolItem>(this.baseApiUrl+"DeleteCvPoolItem",CvPoolItem);
  }
}
