export class Recurre{
    id:number;
    taskId:number;
    frequencyNumber:number;
    frequencyTypeId:number=0;
    startDate:Date;
    endDate:Date;
    nextDueDate:Date;

    isComplated:boolean;
    createdBy:number;
    updatedBy:number;
    deletedBy:number;

}