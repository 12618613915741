import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { DocumentForm } from 'app/evo/models/document-form';
import { DocumentFormApprovalOperationList } from 'app/evo/models/document-form-approval-operation-list';
import { DocumentFormHeader } from 'app/evo/models/document-form-header';
import { DocumentFormList } from 'app/evo/models/document-form-list';
import { DocumentFormRevisionChanges } from 'app/evo/models/document-form-revision-changes';
import { DocumentFormRevisionDetails } from 'app/evo/models/document-form-revision-details';
import { DocumentFormRevisionRequest } from 'app/evo/models/document-form-revision-request';
import { Plan } from 'app/evo/models/plan';
import { PlanList } from 'app/evo/models/planlist.model';
import { PlanType } from 'app/evo/models/plantype';
import { User } from 'app/evo/models/user';
import { FilterPipe } from 'app/evo/pipe/filter.pipe';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoDocumentFormApprovalOperationsService } from 'app/evo/services/evo-document-form-approval-operations.service';
import { EvoDocumentFormHeaderService } from 'app/evo/services/evo-document-form-header.service';
import { EvoDocumentFormRevisionRequestService } from 'app/evo/services/evo-document-form-revision.service';
import { EvoDocumentFormService } from 'app/evo/services/evo-document-form.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

declare var window: any;
declare var bootstrap:any;
const themes = {
    light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
    dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
  };

@Component({
  selector: 'app-evo-document-form-list',
  templateUrl: './evo-document-form-list.component.html',
  styleUrls: ['./evo-document-form-list.component.scss'
  ],
  providers:[FilterPipe]
})
export class EvoDocumentFormComponent implements OnInit,AfterViewInit,OnDestroy {

  constructor(private documentFormService: EvoDocumentFormService,
    private documentFormApprovalOperationService:EvoDocumentFormApprovalOperationsService,
    private documentFormHeaderService: EvoDocumentFormHeaderService,
    private documentFormRevisionRequestService: EvoDocumentFormRevisionRequestService,
    private userService:EvoUserService,
    private transactionService: EvoTransactionService,
    private errorService: ErrorService,
    private informationService: InformationService,
    private helperService: HelperService,
    private decodeService: DecodeService,
    private router: Router,
    public formatter: NgbDateParserFormatter,
    private cd:ChangeDetectorRef,
    private filterPipe: FilterPipe) { }

  public basicSelectedOption: number = 10;
  myId:number;

  documentForms: DocumentFormList[] =[];
  filteredDocumentForms:DocumentFormList[]=[];
  filterText: string = "";
  deletedPlanId: number;

  documentForm:DocumentForm=new DocumentForm();
  documentFormHeader:DocumentFormHeader=new DocumentFormHeader();

  documentFormHeaders:DocumentFormHeader[]=[];
  selectedDocumentForm:DocumentForm=new DocumentForm();
  selectedDocumentFormHeader:DocumentFormHeader=new DocumentFormHeader();

  selectedDocumentFormId:number=0;

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;

  backgroundColor:string="default";

  documentFormHeaderModalVisible:boolean=false;
  oldDocumentForm:DocumentForm = new DocumentForm();
  oldDocumentFormHeader:DocumentFormHeader = new DocumentFormHeader();

  bulkDocumentFormHeaders:DocumentFormHeader[]=[];
  revisionCause:string=null;
  userRole:string="";

  overlayPosition = { top: 0, left: 0 };
  overlayContent: string = '';
  isOverlayVisible: boolean = false;

  approvalTypeFilters:any[]=[
    {id:1,name:'Onay Bekliyor'},
    {id:2,name:'Onaylandı'},
    {id:3,name:'Reddedildi'}
  ];

  selectedApprovalTypes:any[]=[];
  users:User[]=[];
  selectedUsers:any[]=[];

  documentFormApprovalOperationList:DocumentFormApprovalOperationList[]=[]
  filteredDocumentFormApprovalOperationList:DocumentFormApprovalOperationList[]=[]

  p:number=1;
  
  ngOnInit(): void {

    if(localStorage.getItem("tempRedirectReload")=="true"){
        localStorage.removeItem("tempRedirectReload");
        window.location.reload();
    }

    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();

    let item = this.decodeService.getRole();
    this.userRole=item;
    console.log(this.userRole);
    this.myId = this.decodeService.getUserId();
    if (item != "Admin" && item!="Consultant" && item!="ConsultantUser") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 11, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list()
      this.requiredList();
    }
  }

  list() {
    if(this.userRole!="Admin"){
      this.documentFormService.getPendingApprovalListByUserId().subscribe((res:any)=>{
        this.documentForms = res.data;
        this.tableByFiltersId();
        this.informationService.list(res.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      });
    }
    else{
      this.documentFormService.getList().subscribe((items: any) => {
        this.documentForms = items.data;
        this.tableByFiltersId();
        this.informationService.list(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      });
    }

  }


  requiredList() {
    this.userService.getList().subscribe((res: any) => {
      this.users = res.data.map(item => {
        return {
          ...item,  // Spread operator to include all existing properties of the item
          fullName: item.firstName + " " + item.lastName // Adding the fullName property
        };
      });    
    })
  }

  getApprovalListByFormId(id:number){
    if(this.userRole=="Admin"){
      this.documentFormApprovalOperationService.getListByFormId(id).subscribe((res:any)=>{
        this.documentFormApprovalOperationList = res.data;
        this.approvalTableFilter();
  
      })
    }
    else{
      this.documentFormApprovalOperationService.getListByFormAndUserId(id).subscribe((res:any)=>{
        this.documentFormApprovalOperationList = res.data;
        this.approvalTableFilter();
  
      })
    }

  }

  routeToDocumentFormItems(id:number){
    this.documentFormHeaderService.getListByFormId(id).subscribe((items: any) => {
      if(items.data.length==0){
        this.informationService.delete("Verilere erişmek için önce başlık eklemelisiniz.");
      }
      else{
        this.router.navigate(['/home/documentformitems/'+id])
      }
    }, (err) => {
      this.errorService.errorHandler(err);
    });

  }

  ngAfterViewInit(): void {
    const modalElement = document.getElementById('addDocumentFormHeader');
    modalElement?.addEventListener('hide.bs.modal', () => {
      this.onModalHide();
    });

    const modalElement2 = document.getElementById('deleteDocumentFormHeader');
    modalElement2?.addEventListener('hide.bs.modal', () => {
      this.onModalHide();
    });
  }

  onModalHide() {
    var formModal = new window.bootstrap.Modal(document.getElementById('documentFormHeaderList'))
    formModal.show();  
  }

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  approvalTableFilter(){
    let filteredItems = this.documentFormApprovalOperationList;

    if (this.selectedUsers.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
    }

    if (this.selectedApprovalTypes.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedApprovalTypes.includes(item.approvalStatus));
    }

    this.filteredDocumentFormApprovalOperationList = filteredItems;

  }

  tableByFiltersId() {

    let filteredItems = this.documentForms;


    if (this.filterText) {
      filteredItems = this.filterPipe.transform(filteredItems, this.filterText);
    }

    this.filteredDocumentForms = filteredItems;
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Doküman Formları";
    this.helperService.exportExcel(element, title);
  }

  clear(){
    this.documentForm = new DocumentForm();
    this.oldDocumentForm = new DocumentForm();
    this.revisionCause = null;
    this.bulkDocumentFormHeaders = [];
  }

  clearHeader(){
    this.documentFormHeader = new DocumentFormHeader();
    this.oldDocumentFormHeader = new DocumentFormHeader();
    this.revisionCause = null;
  }

  get(id: number) {
    this.documentFormService.get(id).subscribe((item: any) => {
      this.documentForm = {...item.data};
      this.oldDocumentForm = {...item.data};
    });
  }


  compareDocumentForms(
    oldDocumentFormValues: DocumentForm,
    newDocumentFormValues: DocumentForm
  ): DocumentFormRevisionChanges[] {
    const changes: DocumentFormRevisionChanges[] = [];
  
    for (const key in oldDocumentFormValues) {
      if (
        oldDocumentFormValues.hasOwnProperty(key) &&
        newDocumentFormValues.hasOwnProperty(key)
      ) {
        const oldValue = oldDocumentFormValues[key as keyof DocumentForm];
        const newValue = newDocumentFormValues[key as keyof DocumentForm];
  
        // Check if the values are different
        if (oldValue !== newValue) {
          // Capitalize the first letter of the property name
          const affectedColumnName = key.charAt(0).toUpperCase() + key.slice(1);
  
          // Add to changes array
          changes.push({
            affectedColumnName,
            oldValue: oldValue !== undefined ? String(oldValue) : "-",
            newValue: newValue !== undefined ? String(newValue) : "-",
          });
        }
      }
    }
  
    return changes;
  }

  compareDocumentFormHeaders(
    oldDocumentFormValues: DocumentFormHeader,
    newDocumentFormValues: DocumentFormHeader
  ): DocumentFormRevisionChanges[] {
    const changes: DocumentFormRevisionChanges[] = [];
  
    for (const key in oldDocumentFormValues) {
      if (
        oldDocumentFormValues.hasOwnProperty(key) &&
        newDocumentFormValues.hasOwnProperty(key)
      ) {
        const oldValue = oldDocumentFormValues[key as keyof DocumentFormHeader];
        const newValue = newDocumentFormValues[key as keyof DocumentFormHeader];
  
        // Check if the values are different
        if (oldValue !== newValue) {
          // Capitalize the first letter of the property name
          const affectedColumnName = key.charAt(0).toUpperCase() + key.slice(1);
  
          // Add to changes array
          changes.push({
            affectedColumnName,
            oldValue: oldValue !== undefined ? String(oldValue) : "-",
            newValue: newValue !== undefined ? String(newValue) : "-",
          });
        }
      }
    }
  
    return changes;
  }

  determineRevisionParameters(oldDocumentForm:DocumentForm,documentForm:DocumentForm,operationType:string,affectedTableName:string){
    var changes = this.compareDocumentForms(oldDocumentForm,documentForm);

    var documentFormRevision = new DocumentFormRevisionRequest();
    var documentFormRevisionDetails = [];

    var documentFormRevisionDetail = new DocumentFormRevisionDetails();

    documentFormRevisionDetail.affectedRowId = documentForm.id;
    documentFormRevisionDetail.affectedTableName = affectedTableName;
    documentFormRevisionDetail.operationType = operationType;
    documentFormRevisionDetail.changes = changes;
    
    documentFormRevisionDetails = documentFormRevisionDetails.concat(documentFormRevisionDetail);

    documentFormRevision.id = 0;
    documentFormRevision.documentFormId = documentForm.id;
    documentFormRevision.revisionCause = this.revisionCause;
    documentFormRevision.revisionDetails =documentFormRevisionDetails;

    return documentFormRevision;
  }

  
  determineHeaderRevisionParameters(oldDocumentForm:DocumentFormHeader,documentForm:DocumentFormHeader,operationType:string,affectedTableName:string){
    var changes = this.compareDocumentFormHeaders(oldDocumentForm,documentForm);

    var documentFormRevision = new DocumentFormRevisionRequest();
    var documentFormRevisionDetails = [];

    var documentFormRevisionDetail = new DocumentFormRevisionDetails();

    documentFormRevisionDetail.affectedRowId = documentForm.id;
    documentFormRevisionDetail.affectedTableName = affectedTableName;
    documentFormRevisionDetail.operationType = operationType;
    documentFormRevisionDetail.changes = changes;
    
    documentFormRevisionDetails = documentFormRevisionDetails.concat(documentFormRevisionDetail);

    documentFormRevision.id = 0;
    documentFormRevision.documentFormId = documentForm.documentFormId;
    documentFormRevision.revisionCause = this.revisionCause;
    documentFormRevision.revisionDetails =documentFormRevisionDetails;

    return documentFormRevision;
  }



  saveDocumentForm(){

    if(this.documentForm.id==0){
        this.documentForm.revisionDate = null;
        this.documentForm.revisionNo = null;

        this.addDocumentForm();
    }
    else{
        this.updateDocumentForm();
    }

  }

  addDocumentForm(){

    this.documentFormService.add(this.documentForm).subscribe((items: any) => {

      this.informationService.list(items.message)
      this.clear();

    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 150);
    });

  }

  changeAdminApprovalRequirementStatus(){
    this.documentFormService.changeDocumentFormAdminApprovalRequirementStatus(this.documentForm).subscribe((item:any)=>{
      this.informationService.list(item.message)
      this.clear();

    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 150);
    })
  }

  updateDocumentForm(){

    this.documentFormService.update(this.documentForm).subscribe((items: any) => {
      this.informationService.update(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 150);
    });
  }

  delete() {
    this.documentFormService.delete(this.selectedDocumentForm).subscribe((res:any)=>{
      this.informationService.delete("Doküman Formu Silindi");
    },(err:any)=>{
        this.errorService.errorHandler(err);
    }, () => {
        setTimeout(()=>{
            this.list();
        },150)
    });
  }


  sendFormToApproval() {
    this.documentFormService.sendDocumentFormForApproval(this.documentForm).subscribe((res:any)=>{
      this.informationService.delete("Doküman Formu Onaya Gönderildi");
    },(err:any)=>{
        this.errorService.errorHandler(err);
    }, () => {
        setTimeout(()=>{
            this.list();
        },150)
    });
  }

  
  saveDocumentFormHeader(){
    this.documentFormHeader.headerTypeId=1;
    this.documentFormHeader.documentFormId = this.documentForm.id;

    if(this.documentFormHeader.id==0){
        if(this.documentFormHeaders.length>0){
          this.addDocumentFormHeader();
        }
        else{
          this.addDocumentFormHeaderInBulk();
        }
    }
    else{
      this.updateDocumentFormHeader();
    }
  }


  addDocumentFormHeader(){

    this.documentFormHeaderService.add(this.documentFormHeader).subscribe((items: any) => {
      this.informationService.list(items.message)
      this.clearHeader();
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
        setTimeout(()=>{
            this.getDocumentFormHeaders(this.selectedDocumentFormId);
        },150)
    });

  }

  addToBulkHeaders(){
    this.documentFormHeader.headerTypeId=1;
    this.documentFormHeader.documentFormId = this.documentForm.id;
    this.documentFormHeader.id=this.bulkDocumentFormHeaders.length+1;
   
    this.bulkDocumentFormHeaders=this.bulkDocumentFormHeaders.concat(this.documentFormHeader);

    var button = document.getElementById("headerDismissModalButton");
    button.click();
  }

  deleteFromBulkHeaders(id:number){
    this.bulkDocumentFormHeaders =this.bulkDocumentFormHeaders.filter(x=>x.id!=id);
  }

  addDocumentFormHeaderInBulk(){

    this.documentFormHeaderService.addInBulk(this.bulkDocumentFormHeaders).subscribe((items: any) => {
      this.informationService.list(items.message)
      this.clearHeader();
      this.bulkDocumentFormHeaders = [];
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
        setTimeout(()=>{
            this.getDocumentFormHeaders(this.selectedDocumentFormId);
        },150)
    });

  }


  updateDocumentFormHeader(){

    this.documentFormHeaderService.update(this.documentFormHeader).subscribe((items: any) => {

      this.informationService.update(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
        setTimeout(()=>{
            this.getDocumentFormHeaders(this.selectedDocumentFormId);
        },150)
    });

  }

  deleteDocumentFormHeader(){

    this.documentFormHeaderService.delete(this.selectedDocumentFormHeader).subscribe((items: any) => {
        this.informationService.delete(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
          setTimeout(()=>{
              this.getDocumentFormHeaders(this.selectedDocumentFormId);
          },150)
    });

  }



  getDocumentFormHeaders(id:number){
    this.documentFormHeaderService.getListByFormId(id).subscribe((items: any) => {
        this.documentFormHeaders = items.data;
        this.bulkDocumentFormHeaders = [];
        this.informationService.list(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
    });
  }

  getDocumentFormHeader(item:any){
    this.documentFormHeaderService.get(item.id).subscribe((items: any) => {
        this.documentFormHeader = items.data;
        this.oldDocumentFormHeader={...items.data};
        this.informationService.list(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
    });
  }

  openDocumentFormHeaderModal(){
    var formModal = new window.bootstrap.Modal(document.getElementById('addDocumentFormHeader'))
    formModal.show();
  }

  closeDocumentFormHeaderModal(){
    var formModal = new window.bootstrap.Modal(document.getElementById('addDocumentFormHeader'))
    formModal.hide();
  }


  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
  }

  listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      console.log('Background color changed:', backgroundColor);
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cd.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }

  onMouseEnter(event: MouseEvent, description: string): void {
    console.log(description);
    if(description==null){
        description="Onay mesajı girilmemiş";
    }
    this.overlayContent = description; // Set the overlay content

    // Update the position of the overlay based on mouse coordinates
    this.updateOverlayPosition(event);
    this.isOverlayVisible = true;
  }

  // Update the overlay position relative to the mouse position
  onMouseMove(event: MouseEvent): void {
    if (this.isOverlayVisible) {
      this.updateOverlayPosition(event);
    }
  }

  // Update the position of the overlay based on mouse coordinates
  updateOverlayPosition(event: MouseEvent): void {

    const isMenuCollapsed = localStorage.getItem("sidebarCollapsed");

    var mouseX = event.clientX;
    var mouseY = event.clientY;

    if(isMenuCollapsed == "true"){
        mouseX = event.clientX-50;
  
    }
    else{
        mouseX = event.clientX-200;

    }    
    

    this.overlayPosition.left = mouseX + window.scrollX;
    this.overlayPosition.top = mouseY + window.scrollY;
    console.log(this.overlayPosition);
  }

  // Hide the overlay when mouse leaves the description part
  onMouseLeave(): void {
    this.isOverlayVisible = false;
  }



}
