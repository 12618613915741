import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Department } from 'app/evo/models/department';
import { TaskCategory } from 'app/evo/models/taskcategory';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoDepartmentService } from 'app/evo/services/evo-department.service';
import { EvoTaskCategoryService } from 'app/evo/services/evo-task-category.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-task-category-list',
  templateUrl: './evo-task-category-list.component.html',
  styles: [
  ],

})
export class EvoTaskCategoryListComponent implements OnInit {

  constructor(private taskCategoryService:EvoTaskCategoryService,
    private errorService:ErrorService,
    private helperService:HelperService,
    private informationService:InformationService,
    private decodeService:DecodeService,
    private router: Router
    ) { }

  public basicSelectedOption: number = 10;

  todoCategories:TaskCategory[];
  todoCategory:TaskCategory = new TaskCategory();

  filterText: string = "";

  processPending:boolean=false;

  currentPage = 1;
  pageSize = 25;
  mainListSize =0;
  userRole:string="";
  ngOnInit(): void {
    this.userRole =this.decodeService.getRole();
    if(this.userRole !="Admin"){
      this.router.navigate(['/home']);
    }
    else{
      this.list();
    }
  }

  clearTaskCategory(){
    this.todoCategory = new TaskCategory(); 
  }

  list() {
    this.processPending=false;
    this.taskCategoryService.getList().subscribe((items: any) => {
      this.todoCategories = items.data;
      this.mainListSize = this.todoCategories.length;
      this.informationService.list(items.message)
    },(err)=>{
      this.errorService.errorHandler(err);
    });
  }

  getTodoCategory(id:number){
    this.taskCategoryService.get(id).subscribe((res:any)=>{
      this.todoCategory = res.data;

      this.informationService.get(res.message)

    },(err)=>{
      this.errorService.errorHandler(err);
    })

  }

  saveTodoCategory(){
    this.processPending = true;
    if(this.todoCategory.id != 0){
        this.updateTodoCategory();
    }
    else{
        this.addTodoCategory();
    }
  }

  addTodoCategory(){
    this.taskCategoryService.add(this.todoCategory).subscribe((item:any)=>{
        this.informationService.add(item.message);
    },(err)=>{
        this.errorService.errorHandler(err);
    },()=>{
        setTimeout(() => {
            this.list();
          }, 1500);
    })
  }

  updateTodoCategory(){
    this.taskCategoryService.update(this.todoCategory).subscribe((item:any)=>{
        this.informationService.update(item.message);
    },(err)=>{
        this.errorService.errorHandler(err);
    },()=>{
        setTimeout(() => {
            this.list();
          }, 1500);
    }) 
  }

  delete() {
    this.taskCategoryService.delete(this.todoCategory).subscribe((item: any) => {
    this.informationService.delete(item.message)
  },(err)=>{
    this.errorService.errorHandler(err);
  },()=>{
    setTimeout(() => {
      this.list();
    }, 1500);
  });
  }
  
  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Görev Kategorileri";
    this.helperService.exportExcel(element, title);
  }

}
