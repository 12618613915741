export class User {
    id: number;
    userName: string;
    firstName: string;
    title: string;
    lastName: string;
    password?: string;
    mailAddress: string;
    phoneNumber: string;
    genderId: number;
    roleId: number;
    typeId: number;
    partnerId: number;
    imageURL: string;
    vacationCount: number;

    birthdayDate?: any;

    discord?: string;
    gitlab?: string;
    mobileVersion?:string | null = null;

    partnerName: string;
    createdBy: number;
    updatedBy: number;
    deletedBy: number;

    isActive:boolean;

}