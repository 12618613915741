<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="basic-vertical-layouts">
            <div class="row match-height">
                <div class="col-md-12 col-12">
                    <core-card-snippet>
                        <h4 class="card-title">Cari Güncelle</h4>
                        <div class="card-body">
                            <form class="form form-vertical" #UserForm="ngForm">
                                <div class="card-header">
                                    <div class="row col-12">
                                        <div class="col-11"> </div>
                                        <div class="col-1">
                                            <button rippleEffect class="btn btn-success btn-sm" (click)="update()"
                                                title="Kaydet"><i data-feather="save"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead class="text-primary">
                                                <th></th>
                                                <th>
                                                </th>
                                                <th class="text-right">
                                                </th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Partner Adı</td>
                                                    <td> <input type="text" [(ngModel)]="partner.name" required
                                                            id="name-vertical" class="form-control" name="txtFirstName"
                                                            placeholder="Partner Adı" #txtFirstName="ngModel" />

                                                        <small class="text-danger"
                                                            *ngIf="txtFirstName.invalid&& txtFirstName.touched">Partner
                                                            Adı boş
                                                            bırakılamaz!</small>
                                                    </td>
                                                    <td>Partner Ünvanı</td>
                                                    <td>
                                                        <input type="text" [(ngModel)]="partner.title" required
                                                            id="name-vertical" class="form-control" name="txtTitle"
                                                            placeholder="Partnerin Ünvanı" #txtTitle="ngModel" />

                                                        <small class="text-danger"
                                                            *ngIf="txtTitle.invalid&& txtTitle.touched">Partner
                                                            Ünvanı boş
                                                            bırakılamaz!</small>
                                                    </td>
                                                    <td>Vergi Dairesi</td>
                                                    <td>
                                                        <input type="text" ngModel required id="name-vertical"
                                                            class="form-control" name="txtTaxOffice"
                                                            [(ngModel)]="partner.taxOffice" placeholder="Vergi Dairesi"
                                                            #txtTaxOffice="ngModel" />

                                                        <small class="text-danger"
                                                            *ngIf="txtTaxOffice.invalid&& txtTaxOffice.touched">Vergi
                                                            Dairesi boş
                                                            bırakılamaz!</small>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Mail Adresi</td>
                                                    <td>
                                                        <input type="text" [(ngModel)]="partner.mailAddress" required
                                                            id="name-vertical" class="form-control"
                                                            name="txtMailAddress" placeholder="Mail Adresi"
                                                            #txtMailAddress="ngModel" />

                                                        <small class="text-danger"
                                                            *ngIf="txtMailAddress.invalid&& txtMailAddress.touched">Mail
                                                            Adresi
                                                            boş
                                                            bırakılamaz!</small>
                                                    </td>
                                                    <td>Telefon Numarası</td>
                                                    <td>
                                                        <input type="text" [(ngModel)]="partner.phoneNumber" required
                                                            id="name-vertical" class="form-control"
                                                            name="txtPhoneNumber" placeholder="Telefon Numarası"
                                                            #txtPhoneNumber="ngModel" />

                                                        <small class="text-danger"
                                                            *ngIf="txtPhoneNumber.invalid&& txtPhoneNumber.touched">Mail
                                                            Adresi
                                                            boş
                                                            bırakılamaz!</small>
                                                    </td>
                                                    <td>Vergi Numarası</td>
                                                    <td>
                                                        <input type="text" ngModel required id="name-vertical"
                                                            class="form-control" name="txtTaxNumber"
                                                            [(ngModel)]="partner.taxNumber" placeholder="Vergi Numarası"
                                                            #txtTaxNumber="ngModel" />

                                                        <small class="text-danger"
                                                            *ngIf="txtTaxNumber.invalid&& txtTaxNumber.touched">Vergi
                                                            Numarası boş
                                                            bırakılamaz!</small>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Yazışma Adresi</td>
                                                    <td colspan="2">
                                                        <textarea ngModel required id="name-vertical"
                                                            style="height: 75px;" class="form-control"
                                                            [(ngModel)]="partner.mainAddress" name="txtMainAddress"
                                                            placeholder="Yazışma Adresi"
                                                            #txtMainAddress="ngModel"></textarea>

                                                        <small class="text-danger"
                                                            *ngIf="txtMainAddress.invalid&& txtMainAddress.touched">Yazışma
                                                            Adresi
                                                            boş bırakılamaz!</small>
                                                    </td>
                                                    <td>Fatura Adresi</td>
                                                    <td colspan="2">
                                                        <textarea ngModel required id="name-vertical"
                                                            style="height: 75px;" id="name-vertical"
                                                            [(ngModel)]="partner.invoceAddress" class="form-control"
                                                            name="txtInvoceAddress" placeholder="Fatura Adresi"
                                                            #txtInvoceAddress="ngModel"></textarea>

                                                        <small class="text-danger"
                                                            *ngIf="txtInvoceAddress.invalid&& txtInvoceAddress.touched">Fatura
                                                            Adresi boş bırakılamaz!</small>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Açıklaması</td>
                                                    <td [colSpan]="partner.isSupplier? 3:  5">
                                                        <input type="text" ngModel required id="name-vertical"
                                                            class="form-control" name="txtDescription"
                                                            [(ngModel)]="partner.description" placeholder="Açıklama"
                                                            #txtDescription="ngModel" />

                                                        <small class="text-danger"
                                                            *ngIf="txtDescription.invalid&& txtDescription.touched">Açıklama
                                                            boş
                                                            bırakılamaz!</small>
                                                    </td>
                                                    <td *ngIf="partner.isSupplier">Anlaşma Oranı</td>
                                                    <td *ngIf="partner.isSupplier">
                                                        <input type="text" ngModel required id="name-vertical"
                                                            class="form-control" name="txtAgreementRate"
                                                            [(ngModel)]="partner.agreementRate"
                                                            placeholder="Anlaşma Oranı" #txtAgreementRate="ngModel" />

                                                        <small class="text-danger"
                                                            *ngIf="txtAgreementRate.invalid&& txtAgreementRate.touched">Anlaşma
                                                            Oranı boş bırakılamaz!</small>
                                                    </td>
                                                </tr>
                                                <tr
                                                    *ngIf="userRole=='Admin'|| userRole=='Consultant' || userRole=='Customer'">
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div class="custom-control custom-switch">
                                                            <input type="checkbox"
                                                                (change)="changePartnerCustomer($event.target.checked)"
                                                                [checked]="partner.isCustomer"
                                                                class="custom-control-input"
                                                                id="customSwitchCustomer" />
                                                            <label class="custom-control-label"
                                                                for="customSwitchCustomer">Müşteri</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="custom-control custom-switch">
                                                            <input type="checkbox"
                                                                (change)="changePartnerSupplier($event.target.checked)"
                                                                [checked]="partner.isSupplier"
                                                                class="custom-control-input"
                                                                id="customSwitchSupplier" />
                                                            <label class="custom-control-label"
                                                                for="customSwitchSupplier">Tedarikçi</label>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </core-card-snippet>
                    <core-card-snippet>

                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" class="nav nav-pills">

                                <li ngbNavItem>
                                    <a ngbNavLink>Kullanıcıların Listesi</a>
                                    <ng-template ngbNavContent>
                                        <div class="ml-2 mb-2">
                                            <button class="btn btn-sm btn-primary mr-2" title="Yeni Ekle"
                                                data-bs-toggle="modal" data-bs-target="#addpartneruser"><i
                                                    data-feather="plus"></i></button>
                                            <button class="btn btn-sm btn-info" title="Excele Aktar"
                                                (click)="exportExcel()"><i data-feather="file"></i></button>
                                            
                                            <span class="ml-2" *ngIf="userRole=='Admin'">
                                                <p-multiSelect 
                                                [options]="consultantActivityFilters" 
                                                [(ngModel)]="selectedConsultantActivityFilter"
                                                (ngModelChange)="filterPartnerUsers()"
                                                placeholder="Danışman Aktiflik Durumu Seç"
                                                optionLabel="label"
                                                [maxSelectedLabels]="2" 
                                                [selectedItemsLabel]="'...'" 
                                                display="chip" 
                                                [showClear]="false"></p-multiSelect>                
                                            </span>
                                        </div>
                                        <table class="table table-bordered table-hover" style="text-align: center;">
                                            <thead>
                                                <th>Adı Soyadı</th>
                                                <th>Kullanıcı Adı</th>
                                                <th>İşlemler</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of filteredPartnerUsers">
                                                    <td>{{item.firstName}} {{item.lastName}}</td>
                                                    <td>{{item.userName}}</td>
                                                    <td>
                                                        <div class="action-container" style="display: flex; align-items: center;">
                                                          <!-- Switch and Label -->
                                                          <div class="switch-container" style="display: flex; align-items: center; margin-right: 20px;">
                                                            <p-inputSwitch [(ngModel)]="item.isActive" (ngModelChange)="changeUserActivityStatus(item.id)"></p-inputSwitch>
                                                            <span class="label" style="margin-left: 10px; font-weight: bold;">{{ item.isActive ? 'Aktif' : 'Pasif' }}</span>
                                                          </div>
                                                      
                                                          <!-- Update Button -->
                                                          <button class="btn btn-sm btn-warning mr-1" title="Güncelle"
                                                              data-bs-toggle="modal" data-bs-target="#updatepartneruser"
                                                              (click)="getPartnerUserById(item.id)">
                                                            <i data-feather="edit"></i>
                                                          </button>
                                                      
                                                          <!-- Delete Button -->
                                                          <button class="btn btn-sm btn-danger" title="Sil"
                                                              data-bs-toggle="modal" data-bs-target="#deletepartneruser"
                                                              (click)="deletedPartnerUserId=item.id">
                                                            <i data-feather="trash"></i>
                                                          </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table class="table" id="excel-table"
                                            style="text-align: center; display: none;">
                                            <thead>
                                                <th>Adı Soyadı</th>
                                                <th>Kullanıcı Adı</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of partnerUsers">
                                                    <td>{{item.firstName}} {{item.lastName}}</td>
                                                    <td>{{item.userName}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="modal fade" id="addpartneruser" data-bs-backdrop="static"
                                            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Partner
                                                            Kullanıcısı Ekle</h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış
                                                        </button>
                                                    </div>
                                                    <form [formGroup]="addPartnerUserForm"
                                                        (ngSubmit)="addPartnerUser()">
                                                        <div class="modal-body">
                                                            <div class="form-group">
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Ad</label>
                                                                    <input type="text"
                                                                        formControlName="txtUserFirstName"
                                                                        class="form-control" required />
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserFirstName').hasError('required') && addPartnerUserForm.get('txtUserFirstName').touched">
                                                                        Kullanıcının Adı boş bırakılamaz!
                                                                    </small>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Soyad</label>
                                                                    <input type="text" formControlName="txtUserLastName"
                                                                        class="form-control" required />
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserLastName').hasError('required') && addPartnerUserForm.get('txtUserLastName').touched">
                                                                        Kullanıcının Soyadı boş bırakılamaz!
                                                                    </small>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının
                                                                        Başlığı</label>
                                                                    <input type="text" formControlName="txtUserTile"
                                                                        class="form-control" required />
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserTile').hasError('required') && addPartnerUserForm.get('txtUserTile').touched">
                                                                        Kullanıcının Başlığı boş bırakılamaz!
                                                                    </small>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının Mail
                                                                        Adresi</label>
                                                                    <input type="text"
                                                                        formControlName="txtUserMailAdress"
                                                                        class="form-control" required />
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserMailAdress').hasError('required') && addPartnerUserForm.get('txtUserMailAdress').touched">
                                                                        Kullanıcının Mail Adresi boş bırakılamaz!
                                                                    </small>
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserMailAdress').hasError('email')">
                                                                        Geçerli bir mail adresi giriniz!
                                                                    </small>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının Telefon
                                                                        Numarası</label>
                                                                    <input type="text"
                                                                        formControlName="txtUserPhoneNumber"
                                                                        class="form-control" required />
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserPhoneNumber').hasError('required') && addPartnerUserForm.get('txtUserPhoneNumber').touched">
                                                                        Kullanıcının Telefon Numarası boş bırakılamaz!
                                                                    </small>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının
                                                                        Cinsiyeti</label>
                                                                    <select formControlName="slcGender"
                                                                        class="form-control form-select" required>
                                                                        <option *ngFor="let item of genders"
                                                                            [value]="item.id">{{item.name}}</option>
                                                                    </select>
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('slcGender').hasError('required') && addPartnerUserForm.get('slcGender').touched">
                                                                        Kullanıcının Cinsiyeti boş bırakılamaz!
                                                                    </small>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının Rolü</label>
                                                                    <select formControlName="slcRole"
                                                                        class="form-control form-select" required>
                                                                        <option *ngFor="let item of roles"
                                                                            [value]="item.id">{{item.name}}</option>
                                                                    </select>
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('slcRole').hasError('required') && addPartnerUserForm.get('slcRole').touched">
                                                                        Kullanıcının Rolü boş bırakılamaz!
                                                                    </small>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının Adı</label>
                                                                    <input type="text" formControlName="txtUserName"
                                                                        class="form-control" required />
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserName').hasError('required') && addPartnerUserForm.get('txtUserName').touched">
                                                                        Kullanıcı Adı boş bırakılamaz!
                                                                    </small>
                                                                </div>

                                                                <div class="row mb-2 form-group">
                                                                    <label for="name-vertical">Kullanıcının
                                                                        Şifresi</label>
                                                                    <div
                                                                        class="input-group form-password-toggle input-group-merge">
                                                                        <input
                                                                            [type]="passwordTextTypeNew ? 'text' : 'password'"
                                                                            formControlName="txtUserPassword"
                                                                            class="form-control" required />
                                                                        <div class="input-group-append">
                                                                            <span
                                                                                class="input-group-text cursor-pointer"><i
                                                                                    class="feather font-small-4"
                                                                                    [ngClass]="{
                                                            'icon-eye-off': passwordTextTypeNew,
                                                            'icon-eye': !passwordTextTypeNew
                                                          }" (click)="togglePasswordTextTypeNew()"></i></span>
                                                                        </div>
                                                                    </div>
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserPassword').hasError('required') && addPartnerUserForm.get('txtUserPassword').touched">
                                                                        Kullanıcının Şifresi boş bırakılamaz!
                                                                    </small>
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserPassword').hasError('minlength')">
                                                                        Şifre en az 8 karakter olmalıdır.
                                                                    </small>
                                                                    <small class="text-danger"
                                                                        *ngIf="addPartnerUserForm.get('txtUserPassword').hasError('pattern')">
                                                                        Şifre en az bir büyük harf, bir küçük harf
                                                                        ve bir rakam içermelidir.
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="modal-footer">
                                                            <div
                                                                *ngIf="addPartnerUserForm.valid; else emptyAddPartnerUserButton">
                                                                <button type="button" class="btn btn-success"
                                                                    data-bs-dismiss="modal"
                                                                    (click)="addPartnerUser()"><i data-feather="check"
                                                                        class="mr-50"></i>Kaydet</button>
                                                            </div>
                                                            <ng-template #emptyAddPartnerUserButton>
                                                                <button type="submit" rippleEffect
                                                                    class="btn btn-success mr-1"
                                                                    disabled>Kaydet</button>
                                                            </ng-template>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>


                                        <div class="modal fade" id="updatepartneruser" data-bs-backdrop="static"
                                            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Partner
                                                            Kullanıcısı Güncelle
                                                        </h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış
                                                        </button>
                                                    </div>
                                                    <form class="" #UpdatePartnerUser="ngForm">
                                                        <div class="modal-body">
                                                            <div class="form-group">
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının Adı</label>
                                                                    <input type="text" class="form-control"
                                                                        [(ngModel)]="partnerUser.firstName"
                                                                        name="txtUpdateUserFirstName" required
                                                                        #txtUpdateUserFirstName="ngModel">
                                                                    <small class="text-danger"
                                                                        *ngIf="txtUpdateUserFirstName.invalid&& txtUpdateUserFirstName.touched">Kullanıcı
                                                                        Adı boş
                                                                        bırakılamaz!</small>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının
                                                                        Soyadı</label>
                                                                    <input type="text" class="form-control"
                                                                        [(ngModel)]="partnerUser.lastName"
                                                                        name="txtUpdateUserLastName" required
                                                                        #txtUpdateUserLastName="ngModel">

                                                                    <small class="text-danger"
                                                                        *ngIf="txtUpdateUserLastName.invalid&& txtUpdateUserLastName.touched">Partner
                                                                        Unvanı boş
                                                                        bırakılamaz!</small>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının
                                                                        Başlığı</label>
                                                                    <input type="text" class="form-control"
                                                                        [(ngModel)]="partnerUser.title" required
                                                                        name="txtUpdateUserTile"
                                                                        #txtUpdateUserTile="ngModel">

                                                                    <small class="text-danger"
                                                                        *ngIf="txtUpdateUserTile.invalid&& txtUpdateUserTile.touched">Kullanıcının
                                                                        Başlığı boş
                                                                        bırakılamaz!</small>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının Mail
                                                                        Adresi</label>
                                                                    <input type="text" class="form-control"
                                                                        [(ngModel)]="partnerUser.mailAddress" required
                                                                        name="txtUpdateUserMailAdress"
                                                                        #txtUpdateUserMailAdress="ngModel">

                                                                    <small class="text-danger"
                                                                        *ngIf="txtUpdateUserMailAdress.invalid&& txtUpdateUserMailAdress.touched">Mail
                                                                        Adresi boş
                                                                        bırakılamaz!</small>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının Telefon
                                                                        Numarası</label>
                                                                    <input type="text" class="form-control" required
                                                                        [(ngModel)]="partnerUser.phoneNumber"
                                                                        name="txtUpdateUserPhoneNumber"
                                                                        #txtUpdateUserPhoneNumber="ngModel">

                                                                    <small class="text-danger"
                                                                        *ngIf="txtUpdateUserPhoneNumber.invalid&& txtUpdateUserPhoneNumber.touched">Telefon
                                                                        Numarası boş
                                                                        bırakılamaz!</small>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının
                                                                        Cinsiyeti</label>
                                                                    <select name="slcUpdateGender"
                                                                        class="form-control form-select" required
                                                                        #slcUpdateGender="ngModel"
                                                                        [(ngModel)]="partnerUser.genderId">
                                                                        <option *ngFor="let item of genders"
                                                                            value="{{item.id}}">
                                                                            {{item.name}}
                                                                        </option>
                                                                    </select>
                                                                    <small class="text-danger"
                                                                        *ngIf="slcUpdateGender.invalid&& slcUpdateGender.touched">Cinsiyet
                                                                        bilgisi boş
                                                                        bırakılamaz!</small>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının Rolü</label>
                                                                    <select name="slcUpdateRole"
                                                                        class="form-control form-select" required
                                                                        #slcUpdateRole="ngModel"
                                                                        [(ngModel)]="partnerUser.roleId">
                                                                        <option *ngFor="let item of roles"
                                                                            value="{{item.id}}">{{item.name}}
                                                                        </option>
                                                                    </select>
                                                                    <small class="text-danger"
                                                                        *ngIf="slcUpdateRole.invalid&& slcUpdateRole.touched">Rol
                                                                        bilgisi
                                                                        boş
                                                                        bırakılamaz!</small>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının Adı</label>
                                                                    <input type="text" class="form-control"
                                                                        [(ngModel)]="partnerUser.userName"
                                                                        name="txtUpdateUserName" required
                                                                        #txtUpdateUserName="ngModel">

                                                                    <small class="text-danger"
                                                                        *ngIf="txtUpdateUserName.invalid&& txtUpdateUserName.touched">Kullanıcı
                                                                        Adı boş
                                                                        bırakılamaz!</small>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="name-vertical">Kullanıcının
                                                                        Şifresi</label>
                                                                    <input type="text" class="form-control"
                                                                        [(ngModel)]="partnerUser.password"
                                                                        name="txtUpdateUserPassword"
                                                                        #txtUpdateUserPassword="ngModel">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <div
                                                                *ngIf="UpdatePartnerUser.valid;else emptyUpdatePartnerUserButton">
                                                                <button type="submit" rippleEffect
                                                                    class="btn btn-success mr-1"
                                                                    (click)="updatePartnerUser()">Kaydet</button>
                                                            </div>
                                                            <ng-template #emptyUpdatePartnerUserButton>
                                                                <button type="submit" rippleEffect
                                                                    class="btn btn-success mr-1"
                                                                    disabled>Kaydet</button>
                                                            </ng-template>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal fade" id="deletepartneruser" tabindex="-1"
                                            aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Partner
                                                            Kullanıcısını Sil</h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış</button>
                                                    </div>
                                                    <div class="modal-body">
                                                        Kaydı Silmek İstediğinize Emin Misiniz?
                                                    </div>
                                                    <div class="modal-footer">
                                                        <div>
                                                            <button type="button" class="btn btn-danger"
                                                                data-bs-dismiss="modal" (click)="deletePartnerUser()"><i
                                                                    data-feather="trash" class="mr-50"></i>Sil</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                                <li ngbNavItem>
                                    <a ngbNavLink>Projeler Listesi</a>
                                    <ng-template ngbNavContent>


                                        <div class="ml-2 mb-2">
                                            <button class="btn btn-sm btn-primary mr-2" title="Yeni Ekle"
                                                data-bs-toggle="modal" data-bs-target="#addproject"><i
                                                    data-feather="plus"></i></button>
                                            <button class="btn btn-sm btn-info" title="Excele Aktar"
                                                (click)="exportExcelProject()"><i data-feather="file"></i></button>
                                        </div>
                                        <table id="excel-table-project" class="table table-bordered table-hover"
                                            style="text-align: center;display: none;">
                                            <thead>
                                                <th>Proje Başlığı</th>
                                                <th>Durumu</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of projects">
                                                    <td>{{item.title}}</td>
                                                    <td>{{item.statusName}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table class="table table-bordered table-hover" style="text-align: center;">
                                            <thead>
                                                <th>Proje Başlığı</th>
                                                <th>Durumu</th>
                                                <th>İşlemler</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of projects">
                                                    <td>{{item.title}}</td>
                                                    <td>{{item.statusName}}</td>
                                                    <td>
                                                        <button class="btn btn-sm btn-warning mr-1" title="Güncelle"
                                                            data-bs-toggle="modal" data-bs-target="#updateproject"
                                                            (click)="getProject(item.id)"><i
                                                                data-feather="edit"></i></button>
                                                        <button class="btn btn-sm btn-danger" title="Sil"
                                                            data-bs-toggle="modal" data-bs-target="#deleteproject"
                                                            (click)="deletedProjectId=item.id"><i
                                                                data-feather="trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="modal fade" id="addproject" tabindex="-1"
                                            aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5 rounded-circle"
                                                            id="staticBackdropLabel">Proje
                                                            Oluştur</h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış
                                                        </button>
                                                    </div>
                                                    <form class="" #AddProject="ngForm">
                                                        <div class="modal-body">
                                                            <div class="form-group">
                                                                <div class="row mb-2">
                                                                    <label for="title-vertical">Projenin Adı</label>
                                                                    <input type="text" required id="title-vertical"
                                                                        class="form-control" ngModel name="txtTitle"
                                                                        #txtTitle placeholder="Proje ismini giriniz" />
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="title-vertical">Projenin
                                                                        Açıklaması</label>
                                                                    <input type="text" required id="title-vertical"
                                                                        class="form-control" ngModel
                                                                        name="txtDescription" #txtDescription
                                                                        placeholder="Proje açıklaması giriniz" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <div
                                                                *ngIf="AddProject.valid; else emptyAddProjectButtonTemplate">
                                                                <button type="button" class="btn btn-success"
                                                                    data-bs-dismiss="modal"
                                                                    (click)="addProject(txtTitle.value, txtDescription.value)"><i
                                                                        data-feather="check" class="mr-50"
                                                                        data-bs-dismiss="modal"></i>Kaydet</button>
                                                            </div>
                                                            <ng-template #emptyAddProjectButtonTemplate>
                                                                <button type="button" class="btn btn-success"
                                                                    data-bs-dismiss="modal" disabled><i
                                                                        data-feather="check" class="mr-50"
                                                                        data-bs-dismiss="modal"></i>Kaydet</button>
                                                            </ng-template>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal fade" id="updateproject" tabindex="-1"
                                            aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5 rounded-circle"
                                                            id="staticBackdropLabel">Proje
                                                            Güncelle</h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış
                                                        </button>
                                                    </div>
                                                    <form class="" #UpdateProject="ngForm">
                                                        <div class="modal-body">
                                                            <div class="form-group">
                                                                <div class="row mb-2">
                                                                    <label for="title-vertical">Projenin Adı</label>
                                                                    <input type="text" required id="title-vertical"
                                                                        class="form-control" name="txtUpdateTitle"
                                                                        #txtUpdateTitle="ngModel"
                                                                        [(ngModel)]="project.title"
                                                                        placeholder="Proje ismini giriniz" />
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="title-vertical">Projenin
                                                                        Açıklaması</label>
                                                                    <input type="text" required id="title-vertical"
                                                                        class="form-control" name="txtUpdateDescription"
                                                                        #txtUpdateDescription="ngModel"
                                                                        [(ngModel)]="project.description"
                                                                        placeholder="Proje açıklaması giriniz" />
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <label for="tracking-number-vertical">Projenin
                                                                        Durumunu Seçiniz</label>
                                                                    <select class="form-control" required
                                                                        name="slcProjectStatus"
                                                                        [(ngModel)]="project.statusId"
                                                                        #slcProjectStatus="ngModel">
                                                                        <option value="0" selected>Proje Durumu Seçiniz
                                                                        </option>
                                                                        <option *ngFor="let item of projectStatuses"
                                                                            value="{{item.id}}">{{item.name}}</option>
                                                                    </select>
                                                                    <small class="text-danger"
                                                                        *ngIf="slcProjectStatus.invalid&& slcProjectStatus.touched">Bir
                                                                        Proje Durumu seçmeniz
                                                                        gerekiyor!</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <div
                                                                *ngIf="UpdateProject.valid; else emptyUpdateProjectButtonTemplate">
                                                                <button type="button" class="btn btn-success"
                                                                    data-bs-dismiss="modal" (click)="updateProject()"><i
                                                                        data-feather="check" class="mr-50"
                                                                        data-bs-dismiss="modal"></i>Kaydet</button>
                                                            </div>
                                                            <ng-template #emptyUpdateProjectButtonTemplate>
                                                                <button type="button" class="btn btn-success" disabled
                                                                    data-bs-dismiss="modal"><i data-feather="check"
                                                                        class="mr-50"
                                                                        data-bs-dismiss="modal"></i>Kaydet</button>
                                                            </ng-template>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <table class="table" id="excel-table"
                                            style="text-align: center; display: none;">
                                            <thead>
                                                <th>Adı Soyadı</th>
                                                <th>Kullanıcı Adı</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of partnerUsers">
                                                    <td>{{item.firstName}} {{item.lastName}}</td>
                                                    <td>{{item.userName}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </ng-template>
                                </li>
                                <li ngbNavItem *ngIf="partner.isSupplier" (click)="getMyExternalSources()">
                                    <a ngbNavLink>Dış Kaynak Kullanıcılarımın Listesi</a>
                                    <ng-template ngbNavContent>
                                        <div class="ml-2 mb-2">
                                            <button class="btn btn-sm btn-primary mr-2" title="Yeni Ekle"
                                                data-bs-toggle="modal" data-bs-target="#addexternaluser"><i
                                                    data-feather="plus"></i></button>
                                            <button class="btn btn-sm btn-info" title="Excele Aktar"
                                                (click)="exportExcelExternalUsers()"><i
                                                    data-feather="file"></i></button>

                                            <span class="ml-2" *ngIf="userRole=='Admin'">
                                                <p-multiSelect 
                                                [options]="externalActivityFilters" 
                                                [(ngModel)]="selectedExternalActivityFilter"
                                                (ngModelChange)="filterExternalUsers()"
                                                placeholder="Danışman Aktiflik Durumu Seç"
                                                optionLabel="label"
                                                [maxSelectedLabels]="2" 
                                                [selectedItemsLabel]="'...'" 
                                                display="chip" 
                                                [showClear]="false"></p-multiSelect>                
                                            </span>
                                        </div>
                                        <table class="table table-bordered table-hover" style="text-align: center;">
                                            <thead>
                                                <th>Adı Soyadı</th>
                                                <th>Kullanıcı Adı</th>
                                                <th>İşlemler</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of filteredExternalSources">
                                                    <td>{{item.firstName}} {{item.lastName}}</td>
                                                    <td>{{item.userName}}</td>
                                                    <td>

                                                        <button class="btn btn-sm btn-danger" title="Sil"
                                                            data-bs-toggle="modal" data-bs-target="#deleteexternaluser"
                                                            (click)="deletedExternalUserId=item.id"><i
                                                                data-feather="trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table class="table" id="excel-externaluser-table"
                                            style="text-align: center; display: none;">
                                            <thead>
                                                <th>Adı Soyadı</th>
                                                <th>Kullanıcı Adı</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of partnerUsers">
                                                    <td>{{item.firstName}} {{item.lastName}}</td>
                                                    <td>{{item.userName}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="modal fade" id="addexternaluser" data-bs-backdrop="static"
                                            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Dış Kaynak
                                                            Kullanıcısını Ekle</h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış</button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="card-body">
                                                            <form class="form form-vertical"
                                                                #UpdateExternalUserForm="ngForm">
                                                                <div class="col-12">
                                                                    <div class="form-group mb-2">
                                                                        <label for="prioritySelect">Dış Kaynak
                                                                            Kullanıcısı Seçiniz</label>
                                                                        <select class="form-control" required
                                                                            name="slcAddExternalUser"
                                                                            [(ngModel)]="selectedInternalUserId"
                                                                            #slcAddExternalUser="ngModel">
                                                                            <option value="0" selected>Dış Kaynak
                                                                                Kullanıcı Seçiniz</option>
                                                                            <option *ngFor="let item of internalSources"
                                                                                value="{{item.id}}">{{item.firstName}}
                                                                                {{item.lastName}}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <button type="button" rippleEffect
                                                                        class="btn btn-primary m-1 modal-footer"
                                                                        data-bs-dismiss="modal"
                                                                        (click)="addExternalUser(selectedInternalUserId)"><i
                                                                            data-feather="send"
                                                                            class="mr-50"></i>Kaydet</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal fade" id="updateexternaluser" data-bs-backdrop="static"
                                            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Dış Kaynak
                                                            Kullanıcısını Değiştir</h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış</button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="card-body">
                                                            <form class="form form-vertical"
                                                                #UpdateExternalUserForm="ngForm">
                                                                <div class="col-12">
                                                                    <div class="form-group mb-2">
                                                                        <label for="prioritySelect">Dış Kaynak
                                                                            Kullanıcısı Seçiniz</label>
                                                                        <select class="form-control" required
                                                                            name="slcUpdateExternalUser"
                                                                            [(ngModel)]="externalSource.id"
                                                                            #slcUpdateExternalUser="ngModel">
                                                                            <option *ngFor="let item of externalSources"
                                                                                value="{{item.id}}">{{item.firstName}}
                                                                                {{item.lastName}}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <button type="button" rippleEffect
                                                                        class="btn btn-primary m-1 modal-footer"
                                                                        data-bs-dismiss="modal"
                                                                        (click)="updateExternalUser()"><i
                                                                            data-feather="send"
                                                                            class="mr-50"></i>Kaydet</button>
                                                                </div>
                                                            </form>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="modal fade" id="deleteexternaluser" tabindex="-1"
                                            aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Dış Kaynak
                                                            Kullanıcısını Sil</h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış</button>
                                                    </div>
                                                    <div class="modal-body">
                                                        Kaydı Silmek İstediğinize Emin Misiniz?
                                                    </div>
                                                    <div class="modal-footer">
                                                        <div>
                                                            <button type="button" class="btn btn-danger"
                                                                data-bs-dismiss="modal"
                                                                (click)="deleteExternalUser(deletedExternalUserId)"><i
                                                                    data-feather="trash" class="mr-50"></i>Sil</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </ng-template>
                                </li>

                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-1"></div>
                        </div>
                    </core-card-snippet>
                </div>
            </div>
        </section>
        <div class="modal fade" id="deleteproject" tabindex="-1" aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Projeyi
                            Sil</h1>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                    </div>
                    <div class="modal-body">
                        Kaydı Silmek İstediğinize Emin Misiniz?
                    </div>
                    <div class="modal-footer">
                        <div>
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                (click)="deleteProject()"><i data-feather="trash" class="mr-50"></i>Sil</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

