<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section id="ngx-datatable-kitchen-sink">
        <core-card-snippet>
          <h4 class="card-title">CV Havuz Listesi</h4>
          <div class="row ml-2">
            <div class="firstRow mt-1">
              <div style="margin-bottom: 20px;">
                <div class="col-12">
                  <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="search mt-1">
              <div class="col-12">
                <input type="text" class="form-control mr-1" name="filterText" placeholder="CV Arayın.."
                  [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
              </div>
            </div>
            <div class="filter mt-1">
              <div class="col-12">
                <ng-select [multiple]="true" [items]="userTypes" [(ngModel)]="selectedUserTypes"
                  placeholder="Başvuru Tipi Seçiniz" bindLabel="name" bindValue="value" (change)="tableByFiltersId()">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label"> {{ item.name }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </ng-template>
                  <ng-template ng-header-tmp>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="filter mt-1">
              <div class="col-12">
                <ng-select [multiple]="true" [items]="workplacePositions" [(ngModel)]="selectedWorkplacePositions"
                  placeholder="Alan Seçiniz" bindLabel="name" bindValue="value" (change)="tableByFiltersId()">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label"> {{ item.name }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </ng-template>
                  <ng-template ng-header-tmp>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row ml-2">
            <div class="col-12 mb-1">
              <button class="btn btn-primary btn-sm ml-1 mr-1" routerLink="/cv-add" rippleEffect
                title="Yeni CV Ekle">
                <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
              </button>
            </div>
          </div>
          <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
            <thead>
              <th>Başvuran Adı</th>
              <th>Telefon No</th>
              <th>Email</th>
              <th>Çalışmak İstediği Alan</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of cvPoolItemList">
                <td>{{item.fullName}}</td>
                <td>{{item.gsmNo}}</td>
                <td>{{item.email}}</td>
                <td>{{item.workplacePositionName}}</td>
              </tr>
            </tbody>
          </table>
          <ngx-datatable [rows]="filteredCvPoolItemList |filter:filterText" [rowHeight]="58" class="bootstrap core-bootstrap"
            [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
            <ngx-datatable-column name="Adı" prop="fullName" [width]="200">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                {{row.fullName}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="E-Posta" prop="email" [width]="200">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                  {{row.email}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="GSM No" prop="gsmNo" [width]="200">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                  {{row.gsmNo}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="İstediği Pozisyon" prop="workplacePositionName" [width]="200">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                  {{row.workplacePositionName}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Başvuru Tipi" prop="applierType" [width]="200">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                  {{row.applierType}}
                </ng-template>
              </ngx-datatable-column>
            <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <button [title]="'Detaylar'" routerLink="/cv-add/{{row.id}}" class="btn btn-warning btn-sm mr-1">
                    <i class="pi pi-search"></i>
                </button>
                <button [title]="'Sil'" (click)="get(row.id);" data-bs-toggle="modal" data-bs-target="deleteCvItem" class="btn btn-info btn-sm mr-1">
                    <i class="pi pi-trash"></i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </core-card-snippet>

      </section>
    </div>
  </div>


  <div class="modal fade" id="deleteCvItem" tabindex="-1" aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Cv Sil</h1>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        Kaydı Silmek İstediğinize Emin Misiniz?
      </div>
      <div class="modal-footer">
        <div>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
            (click)="deleteCvItem();"><i data-feather="trash" class="mr-50"></i>Sil</button>
        </div>
      </div>
    </div>
  </div>
</div>