import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Plan } from '../models/plan';

@Injectable({
  providedIn: 'root'
})
export class EvoPlanService {
  public events;
  public calendar;
  public currentEvent;
  public tempEvents;

  public onEventChange: BehaviorSubject<any>;
  public onCurrentEventChange: BehaviorSubject<any>;
  public onCalendarChange: BehaviorSubject<any>;


  private baseApiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient,
  ) {
    this.onEventChange = new BehaviorSubject({});
    this.onCurrentEventChange = new BehaviorSubject({});
    this.onCalendarChange = new BehaviorSubject({});
  }

  getList(): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>(this.baseApiUrl + 'GetPlanList');
  }
  getListByUserId(id: number): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>(this.baseApiUrl + 'GetPlanListByUserId/' + id);
  }
  getPlansWithoutActivities(): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>(this.baseApiUrl + 'GetPlansWithoutActivities');
  }

  getNearestPlan(id: number): Observable<Plan> {
    return this.httpClient.get<Plan>(this.baseApiUrl + 'GetNearestPlan/' + id);
  }
  getPlannedDays(id: number): Observable<number[]> {
    return this.httpClient.get<number[]>(this.baseApiUrl + 'GetPlannedDays/' + id);
  }
  getVacationDayListByUserId(userId: number): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>(this.baseApiUrl + 'GetVacationListByUserId/' + userId);
  }
  getVacationList(): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>(this.baseApiUrl + 'GetVacationList/');
  }
  get(id: number): Observable<Plan> {
    return this.httpClient.get<Plan>(this.baseApiUrl + 'GetPlan/' + id);
  }
  add(plan: Plan): Observable<Plan> {
    return this.httpClient.post<Plan>(this.baseApiUrl + 'AddPlan/', plan);
  }
  update(plan: Plan): Observable<Plan> {
    return this.httpClient.post<Plan>(this.baseApiUrl + "UpdatePlan/", plan);
  }
  delete(plan: Plan): Observable<Plan> {
    return this.httpClient.post<Plan>(this.baseApiUrl + "DeletePlan/", plan);
  }

  addToGoogleCalendar(id: number): Observable<Object> {
    return this.httpClient.post<Object>(this.baseApiUrl + "AddEventFromExternalCalendar/" + id, id);
  }

  getFromGoogleCalendar(id: number): Observable<Object> {
    return this.httpClient.get<Object>(this.baseApiUrl + "GetCalendarEvents/" + id);
  }

  checkGoogleAuth(id: number): Observable<Object> {
    return this.httpClient.get<Object>(this.baseApiUrl + "GetGoogleTokenByUserId/" + id);
  }

  deleteGoogleAuth(id: number): Observable<Object> {
    return this.httpClient.post<Object>(this.baseApiUrl + "DeleteGoogleToken/" + id, null);
  }

  getUserVacationDayCount(): Observable<Object> {
    return this.httpClient.get<Object>(this.baseApiUrl + "getUserVacationDayCounts");
  }

  revokeToken(): Observable<Object> {
    return this.httpClient.get<Object>(this.baseApiUrl + "RevokeToken");
  }
}
