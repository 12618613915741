<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section id="ngx-datatable-kitchen-sink">
        <core-card-snippet>
          <h4 class="card-title">Görev Kategorileri Listesi</h4>
          <div class="row">
            <div class="firstRow mt-1">
              <div style="margin-left:9px; margin-bottom: 20px;">
                <div class="col-12">
                  <select class="form-control form-select" [(ngModel)]="pageSize">
                    <option [value]="10">10</option>
                    <option [value]="25">25</option>
                    <option [value]="50">50</option>
                    <option [value]="100">100</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="search mt-1">
              <div class="col-12">
                <input type="text" class="form-control mr-1" name="filterText" placeholder="Görev Kategorisi Arayın.."
                  [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-1 ml-1">
              <button class="btn btn-primary btn-sm ml-1 mr-1" (click)="clearTaskCategory()" data-bs-toggle="modal" data-bs-target="#addTodoCategory" rippleEffect
                title="Yeni Görev Kategorisi">
                <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
              </button>
              <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                <i data-feather="file-text" class=""></i>
              </button>
            </div>
          </div>
          <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
            <thead>
              <th>Görev Kategorisi Adı</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of todoCategories">
                <td>{{item.name}}</td>
              </tr>
            </tbody>
          </table>
          <ngx-datatable [rows]="todoCategories | filter:filterText | paginate: { id:'mainPagination' ,itemsPerPage: pageSize, currentPage: currentPage }" [rowHeight]="58" class="bootstrap core-bootstrap"
            [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
            <ngx-datatable-column name="Adı" prop="name" [width]="600">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                {{row.name.length > 80 ? row.name.slice(0, 80) + '...' : row.name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div ngbDropdown container="body">
                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                      <button (click)="getTodoCategory(row.id)" data-bs-target="#addTodoCategory" data-bs-toggle="modal" ngbDropdownItem><i data-feather="edit-2"
                          class="mr-50"></i><span>Güncelle</span></button>
                      <button ngbDropdownItem (click)="getTodoCategory(row.id)" data-bs-toggle="modal"
                        data-bs-target="#deleteTodoCategory">
                        <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template>
          
                  <div class="d-flex justify-content-center">
                    <pagination-controls
                        id = "mainPagination"

                        [directionLinks]="true" 
                        previousLabel="" 
                        nextLabel="" 
                        (pageChange)="pageChange($event)">
                    </pagination-controls>
                  </div>
                </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </core-card-snippet>
      </section>
    </div>
  </div>

<div class="modal fade" id="deleteTodoCategory" tabindex="-1" aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Departman Sil</h1>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        Kaydı Silmek İstediğinize Emin Misiniz?
      </div>
      <div class="modal-footer">
        <div>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
            (click)="delete()"><i data-feather="trash" class="mr-50"></i>Sil</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addTodoCategory" tabindex="-1" aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Görev Kategorisi Ekle</h1>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        <form class="form form-vertical" #todoCategoryForm="ngForm">
            <div class="row">
              
                <div class="col-12">
                    <div *ngIf="userRole=='Admin'">
                            <div class="form-group">
                                <label for="usrSelect">İsim Giriniz.</label>
                                <input class="form-control" ngModel required name="txtName" [(ngModel)]="todoCategory.name"
                                #txtName="ngModel">
                                <small class="text-danger" *ngIf="txtName.invalid&& txtName.touched">Lütfen bir isim girin.</small>
                            </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="processPending=true;saveTodoCategory()" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                  [disabled]="!todoCategoryForm.valid || processPending==true">Kaydet</button>
            </div>
          </form>      
        </div>
    </div>
  </div>
</div>