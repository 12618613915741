export class PurchaseItem{
    id:number=0;
    name:string;
    quantity:number;
    estimatedMarketPrice:number;
    purchaseEnquiryId:number;
    potentialSuppliers:string;
    approvalStatus:number=1;
    approvalDescription:string;
    technicalQualities:string;
    additionalInfo:string;
    orderDate: any;
    dispatchDate: any;
    invoiceDate: any;

}