
<p-confirmDialog appendTo="body" [baseZIndex]="10000" #confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<div class="container-new">
    <div *ngIf="loading" class="spinner-overlay">
      <div class="spinner-container">
        <p-progressSpinner strokeWidth="4"></p-progressSpinner>
      </div>
    </div>

    <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
            <section id="ngx-datatable-kitchen-sink">
                <core-card-snippet>
                    <h4 class="card-title">Sunucular Listesi</h4>
                    <div class="row">
                        <div class="firstRow mt-1">
                            <div style="margin-left:20px; margin-bottom: 20px;">
                                <div class="col-12 search">
                                    <input type="text" class="form-control mr-1" name="filterText"
                                    placeholder="Servis arayın.." [(ngModel)]="filterText"
                                    [ngModelOptions]="{standalone:true}">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-12 mb-1 ml-1">
                            <button class="btn btn-primary btn-sm ml-1 mr-1" data-bs-toggle="modal"
                                data-bs-target="#addserver" (click)="clear();action='Ekle'" rippleEffect title="Yeni Sunucu Ekle">
                                <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                            </button>
                            <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                                <i data-feather="file-text" class=""></i>
                            </button>
                            <button class="btn btn-warning btn-sm ml-1 mr-1" (click)="checkServerStatusNow()" rippleEffect title="Sunucuları Şimdi Kontrol Et">
                                <i data-feather="link" class=""></i> Şimdi Kontrol Et
                            </button>
                        </div>
                    </div>
    
                    <p-tabView #tabView [scrollable]="true" [(activeIndex)]="activeIndex" (onChange)="setActiveTab()">
    
                        <div class="mb-2">
                            <p-dropdown [options]="allRowCounts" 
                            [(ngModel)]="pageSize" 
                            optionValue="id"
                            optionLabel="label" 
                            >
                            </p-dropdown>               
                        </div>
                        <p-tabPanel header="Sunucu Bilgileri">
                            <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                                <thead>
                                    <tr>
                                        <td>Site Adı</td>
                                        <td>Web Adresi/API Bağlantısı</td>
                                        <td>Açıklaması</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of serverList">
                                        <td>{{item.name}}</td>
                                        <td>{{item.address}}</td>
                                        <td>{{item.description}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ngx-datatable [rows]="filteredServerList|filter:filterText | paginate: { id:'mainPagination' ,itemsPerPage: pageSize, currentPage: currentPage, totalItems: serverListSize }" [rowHeight]="58"
                                class="bootstrap core-bootstrap" [headerHeight]="40" [footerHeight]="50" [columnMode]="'force'"
                                [scrollbarH]="true" [limit]="basicSelectedOption">
                                <ngx-datatable-column name="Site Adı" prop="name" [width]="250">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.name}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Web Adresi/API Bağlantısı" prop="address" [width]="250">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.address}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Açıklama" prop="description" [width]="250">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.description}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <button (click)="get(row.id);action='Değiştir'" data-bs-toggle="modal" data-bs-target="#addserver" class="btn btn-warning btn-sm mr-1">
                                            <i class="pi pi-pencil"></i>
                                        </button>                                          
                                        <button (click)="delete(row.id);" class="btn btn-danger btn-sm">
                                            <i class="pi pi-trash"></i>
                                        </button>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-footer>
                                    <ng-template ngx-datatable-footer-template>
                                
                                        <div class="d-flex justify-content-center">
                                        {{serverListSize }} aktivitenin {{ (currentPage - 1) * pageSize + 1 }} - {{ currentPage * pageSize }} aralığı gösteriliyor. 
    
                                        <pagination-controls
                                            id = "mainPagination"
    
                                            [directionLinks]="true" 
                                            previousLabel="" 
                                            nextLabel="" 
                                            (pageChange)="pageChange($event)">
                                        </pagination-controls>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-footer>
                            </ngx-datatable>
                        </p-tabPanel>
                        <p-tabPanel header="Sunucu Durum Bilgileri">
                            <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                                <thead>
                                    <tr>
                                        <td>Sunucu Adresi</td>
                                        <td>Kontrol Tarihi</td>
                                        <td>Cevap Kodu</td>
                                        <td>Durum</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of serverStatusList">
                                        <td>{{item.address}}</td>
                                        <td>{{item.controlDate}}</td>
                                        <td>{{item.responseCode}}</td>
                                        <td>{{item.status}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <ngx-datatable [rows]="filteredServerStatusList | filter:filterText | paginate: { id:'statusPagination' ,itemsPerPage: pageSize, currentPage: currentServerStatusPage, totalItems: serverStatusListSize }" [rowHeight]="58"
                                class="bootstrap core-bootstrap" [headerHeight]="40" [footerHeight]="50" [columnMode]="'force'"
                                [scrollbarH]="true" [limit]="basicSelectedOption">
                                >
                                <ngx-datatable-column name="Sunucu Adresi" prop="address" >
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.address}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Kontrol Tarihi" prop="controlDate" >
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.controlDate | date:"dd.MM.yyyy HH:mm:ss"}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Cevap Kodu" prop="responseCode" >
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.responseCode}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Durum" prop="status">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.status}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-footer>
                                    <ng-template ngx-datatable-footer-template>
                                
                                        <div class="d-flex justify-content-center">
                                        {{serverStatusListSize }} aktivitenin {{ (currentServerStatusPage - 1) * pageSize + 1 }} - {{ currentServerStatusPage * pageSize }} aralığı gösteriliyor. 
    
                                        <pagination-controls
                                            id = "statusPagination"
    
                                            [directionLinks]="true" 
                                            previousLabel="" 
                                            nextLabel="" 
                                            (pageChange)="serverPageChange($event)">
                                        </pagination-controls>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-footer>
                            </ngx-datatable>
                        
                        </p-tabPanel>
    
                    </p-tabView>
                </core-card-snippet>
    
                <div class="modal fade" id="addserver" tabindex="-1" aria-labelledby="staticBackdropLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Sunucu Ekle</h1>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                            </div>
                            <div class="modal-body">
                                <form class="" #AddServerForm="ngForm">
                                    <div class="modal-body">
                                        <div class="form-group">
                                            <div class="row mb-2">
                                                <label for="name-vertical">Site Adı</label>
                                                <input type="text" [(ngModel)]="server.name" class="form-control" name="txtName" required
                                                    #txtName="ngModel" ngModel>
                                                <small class="text-danger"
                                                    *ngIf="txtName.invalid&& txtName.touched">IP adres kısmı boş
                                                    bırakılamaz!</small>
                                            </div>
                                            <div class="row mb-2">
                                                <label for="name-vertical">Web Site Adresi / API Bağlantısı </label>
                                                <input type="text" [(ngModel)]="server.address" class="form-control" name="txtAddress" required
                                                    #txtAddress="ngModel" ngModel>
                                                <small class="text-danger"
                                                    *ngIf="txtAddress.invalid&& txtAddress.touched">Bağlantı bilgisi kısmı boş
                                                    bırakılamaz!</small>
                                            </div>
    
                                            <div class="row mb-2">
                                                <label for="name-vertical">Kısa Açıklama</label>
                                                <input type="text" [(ngModel)]="server.description" class="form-control" name="txtDescription" required
                                                    #txtDescription="ngModel" ngModel>
                                                <small class="text-danger"
                                                    *ngIf="txtDescription.invalid&& txtDescription.touched">Kısa açıklama kısmı boş
                                                    bırakılamaz!</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <div>
                                            <button type="button" rippleEffect class="btn btn-success mr-1" [disabled]="!AddServerForm.valid"
                                            (click)="saveServer()">Kaydet</button>
                                        </div>
                                    </div>
            
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

</div>