import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PurchaseItem } from '../models/PurchaseItem';

@Injectable({
  providedIn: 'root'
})
export class EvoPurchaseItemService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getListByPurchaseEnquiryId(purchaseEnquiryId:number):Observable<PurchaseItem[]>{
    return this.httpClient.get<PurchaseItem[]>(this.baseApiUrl+'GetPurchaseItemListByPurchaseEnquiryId/'+purchaseEnquiryId);
  }
  get(id: number):Observable <PurchaseItem> {
    return this.httpClient.get<PurchaseItem>(this.baseApiUrl+'GetPurchaseItem/'+id);
  }
  add(PurchaseItem:PurchaseItem):Observable<PurchaseItem>{
    return this.httpClient.post<PurchaseItem>(this.baseApiUrl+'AddPurchaseItem/',PurchaseItem);
  }

  update(PurchaseItem:PurchaseItem):Observable<PurchaseItem>{
    return this.httpClient.post<PurchaseItem>(this.baseApiUrl+"UpdatePurchaseItem/",PurchaseItem);
  }
  delete(id:number):Observable<PurchaseItem>{
    return this.httpClient.post<PurchaseItem>(this.baseApiUrl+"DeletePurchaseItem/"+id,id);
  }

  changeItemApprovalStatus(PurchaseItem:PurchaseItem):Observable<PurchaseItem>{
    return this.httpClient.post<PurchaseItem>(this.baseApiUrl+'ChangePurchaseItemApprovalStatus/',PurchaseItem);
  }
}
