import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { id } from '@swimlane/ngx-datatable';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PartnerUser } from '../models/partneruser';
import { User } from '../models/user';
import { TicketUser } from '../models/ticketuser';
import { Effort } from '../models/effort';

@Injectable({
  providedIn: 'root'
})
export class EvoPartnerUserService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getList(): Observable<PartnerUser[]> {
    return this.httpClient.get<PartnerUser[]>(this.baseApiUrl + 'GetPartnerUserList');
  }
  gePartnerUsertListByPartnerId(partnerId: number): Observable<PartnerUser[]> {
    return this.httpClient.get<PartnerUser[]>(this.baseApiUrl + 'GetPartnerUserListByPartnerId/' + partnerId);
  }
  gePartnerUsertListByUserId(userId: number): Observable<PartnerUser[]> {
    return this.httpClient.get<PartnerUser[]>(this.baseApiUrl + 'GetPartnerUserListByUserId/' + userId);
  }
  getMyExternalSources(partnerId: number): Observable<User[]> {
    return this.httpClient.get<User[]>(this.baseApiUrl + 'GetMyExternalSources/' + partnerId);
  }


  getLastestMessagesForPartner(partnerId: number, pageNumber:number): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseApiUrl + 'GetLastestMessagesForPartner/' + partnerId +"/"+ pageNumber);
  }
  getLastestMessagesForPartnerWithPermission(pageNumber:number, userId:number, secondRestriction:boolean): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseApiUrl + 'GetLastestMessagesForPartnerWithPermission/' + pageNumber +"/"+ userId +"/"+ secondRestriction);
  }
  getWaitingAnswerTicketListForPartner(partnerId: number, pageNumber:number): Observable<TicketUser[]> {
    return this.httpClient.get<TicketUser[]>(this.baseApiUrl + 'GetWaitingAnswerTicketList/' + partnerId+ "/"+ pageNumber);
  }
  getWaitingAnswerTicketListForPartnerWithPermission(pageNumber:number, userId:number, firstRestriction:boolean): Observable<TicketUser[]> {
    return this.httpClient.get<TicketUser[]>(this.baseApiUrl + 'GetWaitingAnswerTicketListWithPermission/' + pageNumber +"/"+ userId +"/"+ firstRestriction);
  }
  getWaitingAcceptionEffortList(partnerId: number, pageNumber:number): Observable<Effort[]> {
    return this.httpClient.get<Effort[]>(this.baseApiUrl + 'GetWaitingAcceptionEffortList/' + partnerId+ "/"+ pageNumber);
  }
  getWaitingAcceptionEffortListWithPermission(pageNumber:number, userId:number, firstRestriction:boolean): Observable<Effort[]> {
    return this.httpClient.get<Effort[]>(this.baseApiUrl + 'GetWaitingAcceptionEffortListWithPermission/' + pageNumber +"/"+ userId +"/"+ firstRestriction);
  }
  
  
  getMyPartnerTicketList(userId: number): Observable<PartnerUser[]> {
    return this.httpClient.get<PartnerUser[]>(this.baseApiUrl + 'GetMyPartnerTicketList/' + userId);
  }
  getMyPartnerTicketActivityList(userId: number): Observable<PartnerUser[]> {
    return this.httpClient.get<PartnerUser[]>(this.baseApiUrl + 'GetMyPartnerTicketActivityList/' + userId);
  }
  getMyPartnerEffortList(userId: number): Observable<PartnerUser[]> {
    return this.httpClient.get<PartnerUser[]>(this.baseApiUrl + 'GetMyPartnerEffortList/' + userId);
  }
  getMyPartnerTicketUserList(userId: number): Observable<PartnerUser[]> {
    return this.httpClient.get<PartnerUser[]>(this.baseApiUrl + 'GetMyPartnerTicketUserList/' + userId);
  }
  getByUserId(userId:number):Observable<PartnerUser>{
    return this.httpClient.get<PartnerUser>(this.baseApiUrl + 'GetPartnerUserByUserId/' + userId);  
  }
  get(id: number): Observable<PartnerUser> {
    return this.httpClient.get<PartnerUser>(this.baseApiUrl + 'GetPartnerUser/' + id);
  }
  add(partnerUser: PartnerUser): Observable<PartnerUser> {
    return this.httpClient.post<PartnerUser>(this.baseApiUrl + 'AddPartnerUser/', partnerUser);
  }
  update(partnerUser: PartnerUser): Observable<PartnerUser> {
    return this.httpClient.post<PartnerUser>(this.baseApiUrl + "UpdatePartnerUser/", partnerUser);
  }
  delete(partnerUser:PartnerUser): Observable<PartnerUser> {
    return this.httpClient.post<PartnerUser>(this.baseApiUrl + "DeletePartnerUser/", partnerUser);
  }
  updateActivityStatus(partner:any):Observable<any>{
    return this.httpClient.post<any>(this.baseApiUrl+"UpdatePartnerUserActivityStatus/",partner);

  }
}
