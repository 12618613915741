import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerUser } from 'app/evo/models/partneruser';
import { Gender } from 'app/evo/models/gender';
import { Role } from 'app/evo/models/role';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { Permission } from 'app/evo/models/permission';
import { PermissionUser } from 'app/evo/models/permissionuser';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';

@Component({
  selector: 'app-evo-customer-user-list',
  templateUrl: './evo-customer-user-list.component.html',
  styles: [
  ]
})
export class EvoPartnerUserListComponent implements OnInit {

  constructor(
    private partnerUserService: EvoPartnerUserService,
    private userService: EvoUserService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,
    private transactionService: EvoTransactionService,
    
    private errorService: ErrorService,
    private informationService: InformationService,
    private helperService: HelperService,
    private decodeService: DecodeService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  basicSelectedOption: number = 10;
  filterText: string = "";

  permissions: Permission[] = [];
  permissionUsers: PermissionUser[] = [];

  canSeeMyTickets: boolean = false;
  canSeeMyActivities: boolean = false;
  canSeeMyEfforts: boolean = false;

  MyTickets: boolean = false;
  MyActivities: boolean = false;
  MyEfforts: boolean = false;

  permissionUser: PermissionUser = new PermissionUser();
  partnerUser: PartnerUser = new PartnerUser();
  gender: Gender = new Gender();
  role: Role = new Role();
  user: User = new User();
  users: User[] = [];

  id: number;
  myId: number;
  userRole: string;
  partnerId: number;
  partnerUsers: PartnerUser[];
  deletedPartnerUserId: number;
  genders: Gender[] = [
    { id: 1, name: "Erkek" },
    { id: 2, name: "Kadın" },
  ];
  roles: Role[] = [
    { id: 1, name: "Müşteri Yönetici" },
    { id: 2, name: "Müşteri Kullanıcı" }
  ]



  ngOnInit(): void {
    this.id = this.route.snapshot.params['Id'];
    this.userRole = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    if (this.userRole != "Admin" && this.userRole != "Customer") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(this.id, 8, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list();
    }
  }

  list() {
    this.partnerUserService.getByUserId(this.id).subscribe((res: any) => {
      this.partnerId = res.data.partnerId;
    })
    this.userService.getList().subscribe((res: any) => {
      this.users = res.data;
    });
    this.partnerUserService.gePartnerUsertListByUserId(this.id).subscribe((res: any) => {
      this.partnerUsers = res.data;
      this.informationService.list(res.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }


  getPermissions(id: number) {
    this.permissionService.getList().subscribe((items: any) => {
      this.permissions = items.data;
    });
    this.permissionUserService.getPermissionUserListByUserId(id).subscribe((items: any) => {
      this.permissionUsers = items.data;
      this.getPermissionsMark(this.permissionUsers);
    });
  }

  getPermissionsMark(permissionUsers: any) {
    permissionUsers.forEach((item) => {

      if (item.permissionId == 2) {
        this.canSeeMyTickets = true;
      }

      if (item.permissionId == 6) {
        this.canSeeMyActivities = true;
      }

      if (item.permissionId == 9) {
        this.canSeeMyEfforts = true;
      }
    });

    this.MyTickets = this.canSeeMyTickets;
    this.MyActivities = this.canSeeMyActivities;
    this.MyEfforts = this.canSeeMyEfforts;
  }

  addPartnerUser(firstName: string, lastName: string, mailAddress: string, phoneNumber: string, title: string, userName: string, password: string) {
    this.partnerUser = {
      id: 0,
      partnerId: this.partnerId,
      partnerName: "",
      userId: 0,
      userName: userName,
      firstName: firstName,
      lastName: lastName,
      mailAddress: mailAddress,
      phoneNumber: phoneNumber,
      password: password,
      title: title,
      genderId: this.gender.id,
      imageUrl: "",
      roleId: this.role.id,
      isActive:true,
      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    }
    this.partnerUserService.add(this.partnerUser).subscribe((item: any) => {
      this.informationService.add(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
      }, 1500);
    });
  }

  getPartnerUserById(id: number) {
    this.partnerUser = new PartnerUser();
    this.clearMarks();
    this.partnerUserService.get(id).subscribe((item: any) => {
      this.partnerUser = item.data;
      this.getPermissions(this.partnerUser.userId);
    });
  }

  updatePartnerUser() {

    if(this.partnerUser.password==null || this.partnerUser.password==undefined){
      this.partnerUser.password=""
    }
    this.partnerUser.partnerId = this.partnerUser.partnerId;
    this.partnerUserService.update(this.partnerUser).subscribe((item: any) => {
      this.informationService.update(item.message);
    });
    setTimeout(() => {
    }, 1500);
  }

  deletePartnerUser(id: number) {
    this.partnerUserService.get(id).subscribe((item: any) => {
      item.data.updatedBy = 0;
      item.data.deletedBy = this.myId;
      item.data.password = "";
      this.partnerUserService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
    });
  }


  addPermissionUser() {
    const permissions = [
      { id: 2, current: this.MyTickets, new: this.canSeeMyTickets },
      { id: 6, current: this.MyActivities, new: this.canSeeMyActivities },
      { id: 9, current: this.MyEfforts, new: this.canSeeMyEfforts },
    ];

    for (const permission of permissions) {
      if (permission.current !== permission.new) {
        const permissionToUpdate = this.permissionUsers.filter(item => item.permissionId === permission.id);

        if (!permission.new) {
          this.deletePermissionUser(permissionToUpdate);
        } else {
          this.addPermissionUserByPermissionId(permission.id);
        }
      }
    }
  }

  addPermissionUserByPermissionId(permissionId: number) {
    this.permissionUser = {
      id: 0,
      permissionId: permissionId,
      userId: this.partnerUser.userId,
      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    };
    this.permissionUserService.add(this.permissionUser).subscribe((item: any) => {
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////** */
  deletePermissionUser(permissionUsers: any) {
    permissionUsers.forEach((item) => {
      item.deletedBy = this.myId;
      item.updatedBy = 0;
      this.permissionUserService.delete(item).subscribe((item: any) => {
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
     
    });
  }


  clearMarks() {
    this.canSeeMyTickets = false;
    this.canSeeMyActivities = false;
    this.canSeeMyEfforts = false;

    this.MyTickets = false;
    this.MyActivities = false;
    this.MyEfforts = false;
  }

  exportExcel() {
    let element = document.getElementById("table-excel");
    let title = "Kullanıcılarım";
    this.helperService.exportExcel(element, title);
  }

}
