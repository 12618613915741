import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CalendarOptions, EventApi, EventClickArg, EventSegment, eventTupleToStore } from '@fullcalendar/angular';
import { EventRef } from 'app/evo/models/calender';
import { takeUntil } from 'rxjs/operators';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { Observable, Subject } from 'rxjs';
import { Partner } from 'app/evo/models/partner';
import { User } from 'app/evo/models/user';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { FormControl } from '@angular/forms';
import { Plan } from 'app/evo/models/plan';
import { InformationService } from 'app/evo/services/information.service';
import { ErrorService } from 'app/evo/services/error.service';
import { PlanType } from 'app/evo/models/plantype';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as menuTurkish } from 'app/menu/i18n/tr';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Router } from '@angular/router';
import { EvoTripPlanService } from 'app/evo/services/evo-trip-plan.service';
import { TripPlan } from 'app/evo/models/tripplan';
import { TripDetail } from 'app/evo/models/tripdetails';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-evo-plandetail',
  templateUrl: './evo-plandetail.component.html',
  styleUrls:['./evo-plandetail.component.scss']
})
export class EvoPlanDetailComponent implements OnInit {

  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  public event: Plan;
  newEvent: any;
  fromDate: Date = new Date();
  toDate: Date = new Date();
  saturdayPlans = false;
  sundayPlans = false;
  public isDataEmpty;

  today: Date = new Date();

  public calendarRef = [];
  public tempRef = [];
  public checkAll = true;

  public slideoutShow = false
  public events = [];

  startedDates: any[] = [];
  endedDates: any[] = [];

  public calendarOptions: CalendarOptions = {
    headerToolbar: {
      start: 'userName, prev, next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    locale: 'tr',
    initialView: 'dayGridMonth',
    initialEvents: this.events,
    weekends: true,
    editable: true,
    eventResizableFromStart: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: 2,
    navLinks: true,
    eventClick: this.handleUpdateEventClick.bind(this),
    eventClassNames: this.getColorWithType.bind(this),
    select: this.handleDateSelect.bind(this),
    moreLinkClick: this.handleMoreLinkClick.bind(this),
    firstDay: 1,
    buttonText: {
      today: 'Bugün',
      month: 'Ay',
      week: 'Hafta',
      day: 'Gün',
      list: 'Liste',
    },
  };

  clicked: boolean = false;
  addMoreDetails: boolean = false;
  addTripDetails: boolean = true;

  planIsInPlace: boolean = false;
  canDelete: boolean = false;

  planIsOutOfTown: boolean = false;
  planHaveBusDetail: boolean = false;
  planHaveHotelDetail: boolean = false;
  planHaveFlightDetail: boolean = false;

  busDetail: boolean = false;
  flightDetail: boolean = false;
  hotelDetail: boolean = false;

  deletedPlanId: number;
  selectedTypes: any[] = [];
  userRole: string;
  myId: number;

  type: PlanType = new PlanType();
  types: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzin" },
  ];
  typeForFilter: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzin" },
    { id: 4, name: "Resmi Tatiller" },
  ];
  tripDetails: TripDetail[] = [
    { id: 1, name: "Otobüs" },
    { id: 2, name: "Uçak" },
    { id: 3, name: "Otel Rezarvasyonu" },
  ];
  private _unsubscribeAll: Subject<any>;
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private partnerService: EvoPartnerService,
    private userService: EvoUserService,
    private transactionService: EvoTransactionService,

    private informationService: InformationService,
    private errorService: ErrorService,
    private _coreSidebarService: CoreSidebarService,
    private planService: EvoPlanService,
    private tripPlanService: EvoTripPlanService,
    private decodeService: DecodeService,
    private router: Router,
  ) {
    this.fromDate.setHours(9, 0, 0, 0);
    this.toDate.setHours(18, 0, 0, 0);
    this._unsubscribeAll = new Subject();
    this.today.setDate(this.today.getDate());
  }
  partners: Partner[] = [];
  selectedUsers: User[] = [];
  tripPlans: TripPlan[] = [];
  users: User[] = [];
  selectedUsersForPlan: any[] = [];
  usersForPlan: User[] = [];
  selectedUserIds: any[] = [];

  dropdownSettings: any = {};

  partner: Partner = new Partner();
  tripPlan: TripPlan = new TripPlan();
  plan: Plan = new Plan();
  user: User = new User();

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'firstName',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.userRole = this.decodeService.getRole();

    if (this.userRole == "Customer" || this.userRole == "CustomerUser") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 11, 5, this.myId).subscribe((res: any) => {
      });
    }
    this.myId = this.decodeService.getUserId();
    this.partnerService.getList().subscribe((res: any) => {
      this.partners = res.data;
    });

    this.type.id = 0;
    this._coreTranslationService.translate(menuTurkish);

    this.partnerService.getList().subscribe((res: any) => {
      this.partners = res.data;
    });
    this.userService.getListForFilter().subscribe((res: any) => {
      this.users = res.data;
      this.usersForPlan = res.data;


    });
    this.list();
    this.getDepartmentList();
  }
  public selectMulti: Observable<any[]>;
  public selectMultiSelected = [{ firstName: 'Cafer Emre Solmaz' }];

  handleMoreLinkClick(arg: any): void {
    arg.jsEvent.preventDefault();

    setTimeout(() => this.movePopoverToBody(), 0);
    

  }

  movePopoverToBody(): void {
    const popover = document.querySelector('.fc-more-popover') as HTMLElement;

    if (popover) {
        // Append popover to body

        // Position the popover absolutely based on its existing computed styles
        const rect = popover.getBoundingClientRect();
        popover.style.position = 'absolute';
        
        popover.style.zIndex = '9999'; // Ensure it stays on top
        popover.style.maxHeight = '300px'; // Set the max height
        popover.style.overflowY = 'auto';
        popover.style.overflowX = 'hidden';

        this.adjustPopoverPosition();
        // Adjust positioning as necessary
    }
}

adjustPopoverPosition(): void {
  const popover = document.querySelector('.fc-more-popover') as HTMLElement;
  const calendar = document.querySelector('.fc-daygrid-body') as HTMLElement;

  if (popover && calendar) {
    const calendarRect = calendar.getBoundingClientRect();
    const popoverRect = popover.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    
    // Adjust top position if it goes too high
    if (popoverRect.top < calendarRect.top) {
      const newTop = calendarRect.top + 10;
      popover.style.top = `${newTop}px`;
    }

    // Adjust left position if it goes out of bounds on the right
    if (popoverRect.right > viewportWidth) {
      const newLeft = viewportWidth - popoverRect.width - 10;
      popover.style.left = `${newLeft}px`;
    }

    console.log(popoverRect.bottom);
    console.log(viewportHeight);

    // Adjust top position if it goes out of bounds on the bottom
    if (popoverRect.bottom > viewportHeight) {
      
      const newTop = calendarRect.height - popoverRect.height - 75;
      popover.style.top = `${newTop}px`;
    }
    
    // Adjust left position if it goes too far left
    if (popoverRect.left < 0) {
      popover.style.left = '10px';
    }

    // Adjust for popover going off screen while scrolled
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    
    if (popoverRect.top < scrollTop) {
      popover.style.top = `${scrollTop + 10}px`;
    }

    if (popoverRect.left < scrollLeft) {
      popover.style.left = `${scrollLeft + 10}px`;
    }
  }
}

  list() {
    if (this.userRole == "Admin") {
      this.planService.getList().subscribe((res: any) => {
        this.calendarOptions.events = res.data.map((event) => {
          if (event.partnerId != 0 || event.userId != 0) {
            return {
              title: event.userName + " - " + event.title + " - " + event.partnerName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }

          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,

              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
    if (this.userRole == "Consultant") {
      this.planService.getList().subscribe((res: any) => {
        this.events = res.data;
        this.calendarOptions.events = res.data.map((event) => {
          if (event.partnerId != 0 || event.userId != 0) {
            return {
              title: event.userName + " - " + event.title + " - " + event.partnerName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,

              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
    if (this.userRole == "ConsultantUser") {
      this.planService.getListByUserId(this.myId).subscribe((res: any) => {
        this.events = res.data;

        this.calendarOptions.events = res.data.map((event) => {
          if (event.partnerId != 0 || event.userId != 0) {
            return {
              title: event.title + " - " + event.partnerName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,

              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
  }


  toggleEventSidebar() {
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
  }

  AddEvent() {
    this.plan = new Plan();
    this.fromDate = new Date();
    this.fromDate.setHours(0, 0, 0, 0); // Today at 00:00
    
    this.toDate = new Date();
    this.toDate.setHours(23, 59, 59, 999); // Today at 23:59
    this.requiredPartnerSelection = false;

    this.resetAllBooleans();

    this.selectedUserIds = []; 
    this.toggleEventSidebar();
    this._coreSidebarService.getSidebarRegistry('calendar-main-sidebar').toggleOpen();
  }

  getColorWithType(data: any) {
    if (data.event._def.extendedProps.calendar == 1) {
      return `bg-light-danger`;
    }
    else if (data.event._def.extendedProps.calendar == 2) {
      return `bg-light-primary`;
    }
    else if (data.event._def.extendedProps.calendar == 3) {
      return `bg-light-success`;
    }
    else if (data.event._def.extendedProps.calendar == 4) {
      return `bg-light-info`;
    }
  }

  populateMultiselect(user: User) {
    var userSelectId = { id: user.id, firstName: user.firstName + " " + user.lastName };
    this.selectedUserIds.push(userSelectId);
  }

  handleUpdateEventClick(eventRef: EventClickArg) {
    this.fromDate = new Date(new Date(eventRef.event._instance.range.start.getFullYear(), eventRef.event._instance.range.start.getMonth(), eventRef.event._instance.range.start.getDate(), eventRef.event._instance.range.start.getHours(), eventRef.event._instance.range.start.getMinutes(), 0).toISOString().slice(0, 16));
    this.toDate = new Date(new Date(eventRef.event._instance.range.end.getFullYear(), eventRef.event._instance.range.end.getMonth(), eventRef.event._instance.range.end.getDate(), eventRef.event._instance.range.end.getHours(), eventRef.event._instance.range.end.getMinutes(), 0).toISOString().slice(0, 16));

    this.resetAllBooleans();
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
    this.requiredPartnerSelection = false;
    this.selectedUserIds = [];
    this.planService.get(parseInt(eventRef.event.id)).subscribe((res: any) => {
      this.plan = res.data;

      if(this.plan.userId != null){
        var user = this.users.find(obj => obj.id === this.plan.userId);
        var userSelectId = { id: user.id, firstName: user.firstName };
        this.selectedUserIds = this.selectedUserIds.concat(userSelectId);
      }

      this.canDeletePlan();
      this.markLocationType(this.plan);
      this.tripPlanService.getListByPlanId(this.plan.id).subscribe((res: any) => {
        this.tripPlans = res.data;

        if(this.tripPlans.length>0){
          if (this.tripPlans[0].tripTypeId != 1) {
            this.tripPlan = this.tripPlans[0];
            this.planIsOutOfTown = true;
            this.markDetails(this.tripPlans);
          }
        }
      });
    });
  }
  selectedPlansEndDate: Date = new Date();

  canDeletePlan() {
    const todayWithoutTime = new Date(this.today);
    todayWithoutTime.setHours(0, 0, 0, 0);

    const selectedEndDateWithoutTime = new Date(this.plan.endDate);
    selectedEndDateWithoutTime.setHours(0, 0, 0, 0);

    if ((selectedEndDateWithoutTime > todayWithoutTime && this.plan.userId == this.myId) || this.userRole == 'Admin') {
      this.canDelete = true;
    } else {
      this.canDelete = false;
    }
  }

  markLocationType(data: any) {
    if (data.locationTypeId == 3) {
      this.planIsInPlace = true;
    }
    else {
      this.planIsInPlace = false;
    }
  }
  markDetails(data: any) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].tripDetailId == 1) {
        this.planHaveBusDetail = true;
      }
      if (data[i].tripDetailId == 2) {
        this.planHaveFlightDetail = true;
      }
      if (data[i].tripDetailId == 3) {
        this.planHaveHotelDetail = true;
      }
    }
    this.busDetail = this.planHaveBusDetail;
    this.flightDetail = this.planHaveFlightDetail;
    this.hotelDetail = this.planHaveHotelDetail;
  }

  update() {
      if(!(this.plan.startDate instanceof Date)){
        this.plan.startDate = new Date(this.fromDate);

      }
      if(!(this.plan.endDate instanceof Date)){
        this.plan.endDate = new Date(this.toDate);
      }

      this.plan.startDate.setHours(this.plan.startDate.getHours() - this.plan.startDate.getTimezoneOffset() / 60)
      this.plan.endDate.setHours(this.plan.endDate.getHours() - this.plan.endDate.getTimezoneOffset() / 60)

      if (this.plan.startDate > this.plan.endDate) {
        let temp;
        temp = this.plan.startDate;
        this.plan.startDate = this.plan.endDate;
        this.plan.endDate = temp;
      }
      
      this.updatePlanLocationType();

      if (this.userRole == "ConsultantUser") {
        this.selectedUserIds = [];
        this.selectedUserIds.push({ id: this.myId, firstName: "default" });
      }

      const ids = this.selectedUserIds.map(user => user.id);

      var plan : Plan = {
        id: this.plan.id,
        title: this.plan.title,
        description: this.plan.description,
        userId: this.plan.userId,
        userName: this.plan.userName,
        partnerId: this.plan.partnerId,
        partnerName: this.plan.partnerName,
        startDate: this.plan.startDate,
        endDate: this.plan.endDate,
        typeId: this.plan.typeId,
        planTypeName: this.plan.planTypeName,
        locationTypeId: this.plan.locationTypeId,
        locationTypeName: this.plan.locationTypeName,
        saturdayExcluded: this.saturdayPlans,
        sundayExcluded: this.sundayPlans,
        createdBy: this.plan.createdBy,
        updatedBy: this.myId,
        deletedBy: 0,
        userIdList: ids,
        tripChangeCheck: true,
        tripPlans: []
      }

      var planIsOutOfTown = this.planIsOutOfTown;
      var planHaveBusDetail = this.planHaveBusDetail;
      var planHaveFlightDetail = this.planHaveFlightDetail;
      var planHaveHotelDetail = this.planHaveHotelDetail;
      const tripDetailsToPlan = [];

      if (planIsOutOfTown === true) {

        if (planHaveBusDetail === true) {
          tripDetailsToPlan.push(this.tripDetails[0]);
        }
        if (planHaveFlightDetail === true) {
          tripDetailsToPlan.push(this.tripDetails[1]);
        }
        if (planHaveHotelDetail === true) {
          tripDetailsToPlan.push(this.tripDetails[2]);
        }
      }

      plan.tripPlans = tripDetailsToPlan;


      this.planService.update(plan).subscribe((res: any) => {

      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.informationService.update("Plan Başarıyla Güncellendi")
          this.list();
          this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').close();
        }, 500)

      });
  }

  updatePlanLocationType() {
    if (this.planIsInPlace == true)
      this.plan.locationTypeId = 3;

    else
      this.plan.locationTypeId = 4;
  }

  deleteTripPlans(tripPlansToUpdate) {
    tripPlansToUpdate.forEach(element => {
      this.tripPlanService.delete(element.id).subscribe((res: any) => {
      });
    });
  }

  addTripPlan(planId: number, tripDetailId: number) {
    this.tripPlan = {
      id: 0,
      planId: planId,
      tripDetailId: tripDetailId,
      tripTypeId: 2,
      planTitle: "",
      planDescription: "",
      planUserId: 0,
      planUserName: "",
      planTypeId: 0,
      planTypeName: "",
      planPartnerId: 0,
      planPartnerName: "",
      planStartDate: new Date(),
      planEndDate: new Date(),

      tripTypeName: "",
      tripDetailName: ""
    };
    this.tripPlanService.add(this.tripPlan).subscribe((res: any) => {
    });
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  handleDateSelect(eventRef, title, description) {
    this.plan = new Plan();
    this.newEvent = new Plan();
    this.selectedUserIds = [];
    this.resetAllBooleans();

    this.newEvent.start = eventRef.start;
    this.newEvent.end = eventRef.end;
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
    this.planService.onCurrentEventChange.next(this.newEvent);

    this.fromDate = new Date(this.newEvent.start.getFullYear(), this.newEvent.start.getMonth(), this.newEvent.start.getDate(), 0, 0, 0);
    this.toDate = new Date(this.newEvent.end.getFullYear(), this.newEvent.end.getMonth(), this.newEvent.end.getDate() - 1, 23, 59, 0);

    this.requiredPartnerSelection = false;
  }
  resetAllBooleans() {
    this.planIsOutOfTown = false;
    this.planHaveBusDetail = false;
    this.planHaveFlightDetail = false;
    this.planHaveHotelDetail = false;
  }
  includeWeekendPlans() {
    if (this.plan.startDate > this.plan.endDate) {
      let temp;
      temp = this.plan.startDate;
      this.plan.startDate = this.plan.endDate;
      this.plan.endDate = temp;
    }

    if (this.saturdayPlans == true && this.sundayPlans == false) {

      const savedDates = [];
      const startDates = [];
      const endDates = [];

      const fromDate = new Date(this.fromDate);
      const toDate = new Date(this.toDate);

      fromDate.setHours(fromDate.getHours() - (fromDate.getTimezoneOffset() / 60));
      toDate.setHours(toDate.getHours() - (toDate.getTimezoneOffset() / 60));

      let startDate;

      while (fromDate <= toDate) {
        const dayOfWeek = fromDate.getDay();

        if (dayOfWeek !== 6) {
          savedDates.push(new Date(fromDate));
          if (!startDate) {
            startDate = new Date(fromDate);
          }
        } else {
          if (savedDates.length > 0) {
            startDates.push(startDate);
            let enddate = new Date(savedDates[savedDates.length - 1])
            enddate.setHours(toDate.getHours())
            endDates.push(enddate);
          }
          startDate = null;
          savedDates.length = 0;
        }

        fromDate.setDate(fromDate.getDate() + 1);

      }

      if (savedDates.length > 0) {
        startDates.push(startDate);
        let enddate = new Date(savedDates[savedDates.length - 1])
        enddate.setHours(toDate.getHours())
        endDates.push(enddate);
      }
      this.startedDates = startDates;
      this.endedDates = endDates;
    }

    if (this.sundayPlans == true && this.saturdayPlans == false) {
      const savedDates = [];
      const startDates = [];
      const endDates = [];

      const fromDate = new Date(this.fromDate);
      const toDate = new Date(this.toDate);

      fromDate.setHours(fromDate.getHours() - (fromDate.getTimezoneOffset() / 60));
      toDate.setHours(toDate.getHours() - (toDate.getTimezoneOffset() / 60));

      let startDate;

      while (fromDate <= toDate) {
        const dayOfWeek = fromDate.getDay();

        if (dayOfWeek !== 0) {
          savedDates.push(new Date(fromDate));
          if (!startDate) {
            startDate = new Date(fromDate);
          }
        } else {
          if (savedDates.length > 0) {
            startDates.push(startDate);
            let enddate = new Date(savedDates[savedDates.length - 1])
            enddate.setHours(toDate.getHours())
            endDates.push(enddate);
          }
          startDate = null;
          savedDates.length = 0;
        }
        fromDate.setDate(fromDate.getDate() + 1);
      }

      if (savedDates.length > 0) {
        startDates.push(startDate);
        let enddate = new Date(savedDates[savedDates.length - 1])
        enddate.setHours(toDate.getHours())
        endDates.push(enddate);
      }

      this.startedDates = startDates;
      this.endedDates = endDates;
    }

    if (this.sundayPlans == true && this.saturdayPlans == true) {
      const savedDates = [];
      const startDates = [];
      const endDates = [];

      // const fromDate = this.fromDate;
      // const toDate = this.toDate;
      const fromDate = new Date(this.fromDate);
      const toDate = new Date(this.toDate);

      fromDate.setHours(fromDate.getHours() - (fromDate.getTimezoneOffset() / 60));
      toDate.setHours(toDate.getHours() - (toDate.getTimezoneOffset() / 60));

      let startDate;

      while (fromDate <= toDate) {
        const dayOfWeek = fromDate.getDay();

        if (dayOfWeek !== 6 && dayOfWeek !== 0) {
          savedDates.push(new Date(fromDate));
          if (!startDate) {
            startDate = new Date(fromDate);
          }
        } else {
          if (savedDates.length > 0) {
            startDates.push(startDate);
            let enddate = new Date(savedDates[savedDates.length - 1])
            enddate.setHours(toDate.getHours())
            endDates.push(enddate);
          }
          startDate = null;
          savedDates.length = 0;
        }


        fromDate.setDate(fromDate.getDate() + 1);
      }

      if (savedDates.length > 0) {
        startDates.push(startDate);
        let enddate = new Date(savedDates[savedDates.length - 1])
        enddate.setHours(toDate.getHours())
        endDates.push(enddate);
      }

      this.startedDates = startDates;
      this.endedDates = endDates;
    }
  }

  addUserToPlan() {
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').close();
  }
  // selectMultiUser(){
  //   this.selectedUsersForPlan.forEach(element => {
  //     this.selectedUserIds.push(element.id);
  //     console.log(this.selectedUserIds);
  //   });

  // }

  urlEncodeForGoogle(url: string): string {
    const unreservedChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-.~";
    let result = '';

    for (const symbol of url) {
      if (unreservedChars.indexOf(symbol) !== -1) {
        result += symbol;
      } else {
        result += '%' + symbol.charCodeAt(0).toString(16).toUpperCase();
      }
    }

    return result;
  }

  authToGoogle() {
    var redirectURL = environment.apiUrl + "AuthorizeGoogle";
    var prompt = "consent"
    var response_type = "code";
    var clientID = environment.googleClientId;
    var scope = "https://www.googleapis.com/auth/calendar";
    var access_type = "offline";
    var state  = JSON.stringify({ userId: this.myId });
    var redirect_uri_encode = this.urlEncodeForGoogle(redirectURL);
    var scopeURL1 = "https://accounts.google.com/o/oauth2/auth?redirect_uri=" + redirect_uri_encode + "&prompt=" + prompt + "&response_type=" + response_type + "&client_id=" + clientID + "&scope=" + scope + "&access_type=" + access_type + "&state=" + state + "&response_mode=form_post";
    window.location.href = scopeURL1;
  }

  syncWithGoogle() {

    this.planService.checkGoogleAuth(this.myId).subscribe((result: any) => {
      var googleAuthInfo = result.data;
      if (googleAuthInfo != null) {

        this.planService.addToGoogleCalendar(this.myId).subscribe((res: any) => {

        },(error)=>{
          this.planService.deleteGoogleAuth(this.myId).subscribe(() => {

          })

          this.informationService.delete("Lütfen önce Google hesabınıza giriş yapın.");

          setTimeout(() => {
            this.authToGoogle();

          }, 1500);


        },()=>{
          this.informationService.add("Senkronizasyon başarıyla tamamlandı.");
          setTimeout(() => {
            window.location.reload();
          }, 1500)
        });
      }
      else {

        this.informationService.delete("Lütfen önce Google hesabınıza giriş yapın.");

        setTimeout(() => {
          this.authToGoogle();
        }, 1500);

      }

    })
  }


  revokeToken(){
  
    this.planService.revokeToken().subscribe((res:any)=>{
      this.informationService.warning("Google Takvim İzni Kaldırıldı");

    },(err:any)=>{
      this.informationService.delete("Bir hata oluştu");
    })
  }


  add() {

      // let formDate = this.fromDate;
      // let toDate = this.toDate;

      // this.fromDate.setHours(this.fromDate.getHours() + 3);
      // this.toDate.setHours(this.toDate.getHours() + 3);

      let formDate = new Date(this.fromDate);
      let toDate = new Date(this.toDate);

      formDate.setHours(formDate.getHours() - (toDate.getTimezoneOffset() / 60));
      toDate.setHours(toDate.getHours() - (toDate.getTimezoneOffset() / 60));

      if (this.userRole == "ConsultantUser") {
        this.selectedUserIds = [];
        this.selectedUserIds.push({ id: this.myId, firstName: "default" });
      }

      const ids = this.selectedUserIds.map(user => user.id);
      console.log(ids);
      this.plan = {
        id: 0,
        userId: this.myId,
        // userId:this.userRole == "ConsultantUser" ? this.myId : this.plan.userId,
        partnerId: this.plan.partnerId,
        startDate: formDate,
        endDate: toDate,
        title: this.plan.title,
        description: this.plan.description,
        typeId: this.plan.typeId,
        userName: "",
        partnerName: "",
        planTypeName: "",
        locationTypeId: this.planIsInPlace == true ? 3 : 4,
        locationTypeName: "",
        saturdayExcluded:this.saturdayPlans,
        sundayExcluded:this.sundayPlans,
        createdBy: this.myId,
        updatedBy: 0,
        deletedBy: 0,
        tripChangeCheck:true,
        tripPlans:[],
        userIdList:ids
      };

      const tripDetailsToPlan = [];

      if (this.planIsOutOfTown === true) {

        if (this.planHaveBusDetail === true) {
          tripDetailsToPlan.push(this.tripDetails[0]);
        }
        if (this.planHaveFlightDetail === true) {
          tripDetailsToPlan.push(this.tripDetails[1]);
        }
        if (this.planHaveHotelDetail === true) {
          tripDetailsToPlan.push(this.tripDetails[2]);
        }
      }

      this.plan.tripPlans = tripDetailsToPlan;

      this.planService.add(this.plan).subscribe((res: any) => {

      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {

          this.informationService.add("Plan Başarıyla Kaydedildi")
          this.list();
          this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').close();

        }
      );
  }

  delete(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;

      this.planService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.list()
          this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').close();
        }, 500);
      });
    });
  }

  changeSaturdayPlan() {
    if (this.saturdayPlans == true) {
      this.saturdayPlans = false;
    }
    else {
      this.saturdayPlans = true;
    }
    this.includeWeekendPlans();
  }

  changeSundayPlan() {
    if (this.sundayPlans == true) {
      this.sundayPlans = false;
    }
    else {
      this.sundayPlans = true;
    }
    this.includeWeekendPlans();
  }

  changeTripType() {
    this.planIsOutOfTown = !this.planIsOutOfTown;
  }
  changeLocationType() {
    this.planIsInPlace = !this.planIsInPlace;

  }
  requiredPartnerSelection: boolean = false;
  changeType(type: number) {
    if (type == 1 || type == 2) {
      this.requiredPartnerSelection = false;
    }
    else {
      this.requiredPartnerSelection = true;
    }
  }

  tableByFiltersId() {
    if (this.userRole == "Admin") {

      this.planService.getList().subscribe((items: any) => {
        let filteredItems = items.data;

        if (this.selectedTypes.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedTypes.includes(item.typeId));
        }

        if (this.selectedUsers.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
        }

        this.calendarOptions.events = filteredItems.map((event) => {
          if (event.partnerId != 0) {
            return {
              title: event.partnerName + " - " + event.userName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });

    }
    if (this.userRole == "Consultant") {
      this.planService.getList().subscribe((items: any) => {
        let filteredItems = items.data;

        if (this.selectedTypes.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedTypes.includes(item.typeId));
        }

        if (this.selectedUsers.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
        }

        this.calendarOptions.events = filteredItems.map((event) => {
          if (event.partnerId != 0) {
            return {
              title: event.partnerName + " - " + event.userName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
    if (this.userRole == "ConsultantUser") {
      this.planService.getListByUserId(this.myId).subscribe((items: any) => {
        let filteredItems = items.data;

        if (this.selectedTypes.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedTypes.includes(item.typeId));
        }

        this.calendarOptions.events = filteredItems.map((event) => {
          if (event.partnerId != 0) {
            return {
              title: event.partnerName + " - " + event.userName,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
          else {
            return {
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              calendar: event.typeId,
              groupId: 1,
              id: event.id,
              allDay: false,
              description: event.description,
            }
          }
        })
      });
    }
  }

  toggleCheckboxAll(event) {
    this.checkAll = event.target.checked;
    if (this.checkAll) {
      this.calendarRef.map(res => {
        res.checked = true;
      });
    } else {
      this.calendarRef.map(res => {
        res.checked = false;
      });
    }
  }


  mappedUsers: User[] = [];
  public selectControlDepartment = new FormControl();

  getDepartmentList() {
    this.userService.getListForFilter().subscribe((res: any) => {
      this.users = res.data;
      this.mappedUsers = res.data.map((x: any) => {
        return { optionId: x.id, optionTitle: x.firstName };
      })
    });
  }
  changeSectorId() {
    this.plan.userId = this.selectControlDepartment.value;
  }
}
