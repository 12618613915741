import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { Plan } from 'app/evo/models/plan';
import { PlanType } from 'app/evo/models/plantype';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-plan-update-form',
  templateUrl: './evo-plan-update-form.component.html',
  styles: [
  ]
})
export class EvoPlanUpdateFormComponent implements OnInit {

  constructor(
    private planService: EvoPlanService,
    private partnerService: EvoPartnerService,
    private userService: EvoUserService,
    private transactionService: EvoTransactionService,

    private router: Router,
    private route: ActivatedRoute,
    private informationService: InformationService,
    private errorService: ErrorService,
    private decodeService: DecodeService,
  ) { }

  types: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzinler" }
  ];

  id: number;
  myId: number;
  users: User[] = [];
  partners: Partner[] = [];

  user: User = new User();
  partner: Partner = new Partner();
  plan: Plan = new Plan();

  ngOnInit(): void {
    let item = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    this.id = this.route.snapshot.params['Id'];

    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(this.id, 11, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.planService.get(this.id).subscribe((item: any) => {
        this.plan = item.data;
      });
      this.partnerService.getList().subscribe((res: any) => {
        this.partners = res.data;
      });
      this.userService.getList().subscribe((res: any) => {
        this.users = res.data;
      });
    }

  }
  update() {
    this.plan.saturdayExcluded=false;
    this.plan.sundayExcluded=false;
    this.plan.updatedBy = this.myId;
    this.plan.deletedBy = 0;
    this.plan.userIdList = [this.plan.userId];
    this.plan.tripChangeCheck = false;
    this.plan.tripPlans = [];

    var realStartDate = new Date(this.plan.startDate);
    realStartDate.setHours(realStartDate.getHours() - (realStartDate.getTimezoneOffset() / 60));
    var realEndDate = new Date(this.plan.endDate);
    realEndDate.setHours(realEndDate.getHours() - (realEndDate.getTimezoneOffset() / 60));

    this.plan.startDate = realStartDate;
    this.plan.endDate = realEndDate;
    
    this.planService.update(this.plan).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/plans"]);
      }, 1500);
    });
  }
}
