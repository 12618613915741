import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Recurre } from 'app/evo/models/reccure';
import { Task } from 'app/evo/models/task';
import { TaskStatus } from 'app/evo/models/tasskstatus';
import { Todo } from 'app/evo/models/todo.model';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoRecurreService } from 'app/evo/services/evo-recurre.service';
import { EvoTaskStatusService } from 'app/evo/services/evo-task-status.service';
import { EvoTaskService } from 'app/evo/services/evo-task.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';
import { DragulaService } from 'ng2-dragula';

import { DatePipe } from '@angular/common';

import { DropEffect } from 'ngx-drag-drop';
import { EvoTaskCategoryService } from 'app/evo/services/evo-task-category.service';
@Component({
  selector: 'app-evo-todo',
  templateUrl: './evo-todo.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./evo-todo.component.scss"
  ],
  host: { class: 'todo-application' }
})
export class EvoTodoComponent implements OnInit {
  todos: Todo[] = [

    // Buraya istediğiniz kadar todo öğesi ekleyebilirsiniz
  ];
  alertClose: boolean = false;
  @Input() todo: any;
  public filters: Array<{}>;
  public tags: Array<{}>;
  filterText: string = "";
  filterPastText:string="";
  public hoveredDate: any = null;
  public hoveredPastDate: any = null;

  public fromDateExample: any = null;
  public toDateExample: any = null
  public fromPastDateExample: any = null;
  public toPastDateExample: any = null
  fromDate: Date;
  toDate: Date;
  fromPastDate: Date;
  toPastDate: Date;

  nostartpage: number = 1;
  progresspage: number = 1;
  donepage: number = 1;

  selectedFrequencies = []
  selectedPastFrequencies = [];
  public isDataEmpty;
  public selectTags;
  public selectAssignee;

  @ViewChild('dueDateRef') private dueDateRef: any;

  public dueDateOptions = {
    altInput: true,
    mode: 'single',
    altInputClass: 'form-control flat-picker flatpickr-input invoice-edit-input',
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d'
  };
  //Denemelerim
  task1: Task = new Task();
  recurre1: Recurre = new Recurre();


  userId: number = 0;
  userRole: string = "";

  task: Task = new Task();
  recurre: Recurre = new Recurre();
  taskStatus: TaskStatus = new TaskStatus();

  tasks: Task[] = [];
  pastTasks:Task[]=[];
  filteredPastTasks:Task[]=[];
  isReccuren: boolean = true;

  doneTasks: Task[] = []
  inprogressTasks: Task[] = []
  users: User[] = [];
  selectedUsers:any[]=[];
  selectedPastUsers:any[]=[];

  taskTypes: any[] = [
    { id: 1, name: "Düşük" },
    { id: 2, name: "Normal" },
    { id: 3, name: "Yüksek" },
  ];
  frequencyTypes: any[] = [
    { id: 1, name: "Günlük" },
    { id: 2, name: "Haftalık" },
    { id: 3, name: "Aylık" },
    { id: 4, name: "Yıllık" },
  ];
  statusTypes: any[] = [
    { id: 4, name: "Tamamlandı" },
    { id: 5, name: "Tamamlanamadı" },
  ];

  selectedPastStatus:any[]=[];
  dateFilter: any[] = [
    { id: 1, name: "Hepsi" },
    { id: 2, name: "Bu Ay" },
    { id: 3, name: "Geçen Ay" },
    { id: 4, name: "Özel" }

  ];

  selectedDateFilter:any=2;
  selectedPastDateFilter:any=2;
  considerDateFilter:boolean=true;
  considerPastDateFilter:boolean=true;

  selectedTaskCategoryFilter:any[]=[];
  selectedPastTaskCategoryFilter:any[]=[];

  taskCategories:any[]=[];

  taskInProcess:boolean=false;

  constructor(
    private taskService: EvoTaskService,
    private taskCategoryService:EvoTaskCategoryService,
    private taskStatusService: EvoTaskStatusService,
    private recurreService: EvoRecurreService,
    private userService: EvoUserService,
    public formatter: NgbDateParserFormatter,
    private decodeService: DecodeService,
    private _coreSidebarService: CoreSidebarService,
    private informationService: InformationService,
    private errorService: ErrorService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.userId = this.decodeService.getUserId();
    this.userRole = this.decodeService.getRole();

    this.selectedUsers.push(this.userId);

    if (this.userRole == "Customer" || this.userRole == "CustomerUser") {
      this.informationService.warning("Bu sayfaya erişim yetkiniz bulunmamaktadır.");
      this.router.navigate(['/home']);
    }

    this.requiredData();

    this.tableByFiltersId();
  }


  requiredData(){
    this.userService.getList().subscribe((res: any) => {
      this.users = res.data.map(item => {
        return {
          ...item,  // Spread operator to include all existing properties of the item
          fullName: item.firstName + " " + item.lastName // Adding the fullName property
        };
      });    
    })

    this.taskCategoryService.getList().subscribe((res:any)=>{
      this.taskCategories = res.data;
    })
  }

  onDrop(event: any, statusId: number, list?: any[]) {
    if (list && (event.dropEffect == 'copy' || event.dropEffect == 'move')) {
      let index = event.index;
      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);
      let todo = event.data;
      todo.statusId = statusId
      todo.updatedBy = this.userId;
      this.taskService.update(todo).subscribe((res: any) => {
        this.informationService.update("Görev güncellendi");

        this.tableByFiltersId();
      })
    }
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  getIncompleteTasks(){
    this.selectedPastUsers = [];
    this.selectedPastFrequencies = [];
    this.taskService.getPastTasks().subscribe((res:any)=>{
      this.pastTasks = res.data;
      this.filteredPastTasks.push(undefined);
      setTimeout(()=>{
        this.filteredPastTasks = this.pastTasks;
        this.pastTableByFiltersId();

      },100)
      
      
    })
  }

  getList() {
    this.userService.getListForFilter().subscribe((items: any) => {
      this.users = items.data;
    });
  }

  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  onPastDateSelection(date: NgbDate) {
    if (!this.fromPastDateExample && !this.toPastDateExample) {
      this.fromPastDateExample = date;
    } else if (this.fromPastDateExample && !this.toPastDateExample && date && date.after(this.fromPastDateExample)) {
      this.toPastDateExample = date;
    } else {
      this.toPastDateExample = null;
      this.fromPastDateExample = date;
    }
    this.tableByPastDates();
  }

  pastTableByFiltersId(){
    let filteredItems = this.pastTasks as any;
    if (this.selectedPastUsers.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedPastUsers.includes(item.userId));
    }
    if(this.selectedPastFrequencies.length > 0){
      filteredItems = filteredItems.filter(item => this.selectedPastFrequencies.includes(item.frequencyTypeId));

    }
    if(this.selectedPastStatus.length > 0){
      filteredItems = filteredItems.filter(item => this.selectedPastStatus.includes(item.statusId));
    }

    if (this.selectedPastTaskCategoryFilter.length > 0) {
      filteredItems = filteredItems.filter(item =>
          item.taskCategoryList.some(categoryId => this.selectedPastTaskCategoryFilter.includes(categoryId))
      );
    }


    if(this.selectedPastDateFilter == 1){
      this.considerPastDateFilter = false;
      this.fromPastDateExample = null;
      this.toPastDateExample = null;
      this.toPastDate = null;
      this.fromPastDate = null;
    }
    if(this.selectedPastDateFilter==4){
      this.considerPastDateFilter = true;
      [this.fromPastDate,this.toPastDate] = this.constructDatesBasedOnType(this.fromPastDateExample,this.toPastDateExample);
    }
    if(this.selectedPastDateFilter == 2 || this.selectedPastDateFilter == 3 ){
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();
      var datePipe = new DatePipe("en-US");
      this.considerPastDateFilter = false;
      if (this.selectedPastDateFilter == 2) { // For "This Month"
        this.fromPastDateExample = new Date(now.getFullYear(), now.getMonth(), 1);
        this.toPastDateExample = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Setting day 0 gives last day of the previous month
        
        this.toPastDateExample = datePipe.transform(this.toPastDateExample,'yyyy-MM-dd');
        this.fromPastDateExample = datePipe.transform(this.fromPastDateExample, 'yyyy-MM-dd');
        
        filteredItems = filteredItems.filter(item => {
          var itemStartDate = new Date(item.startDate);
      
          return (
            (itemStartDate.getMonth() === currentMonth && itemStartDate.getFullYear() === currentYear)
          );
        });
      }
      else{
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    
        this.fromPastDateExample = new Date(lastMonthYear, lastMonth, 1);
        this.toPastDateExample = new Date(lastMonthYear, lastMonth + 1, 0);
        
        this.toPastDateExample = datePipe.transform(this.toPastDateExample,'yyyy-MM-dd');
        this.fromPastDateExample = datePipe.transform(this.fromPastDateExample, 'yyyy-MM-dd');
        
        filteredItems = filteredItems.filter(item => {
          var itemStartDate = new Date(item.startDate);
      
          return (
            (itemStartDate.getMonth() === lastMonth && itemStartDate.getFullYear() === lastMonthYear)
          );
        });

      }
    }

    if(this.considerPastDateFilter == true){
      if (this.fromPastDate || this.toPastDate) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.startDate) >= new Date(this.fromPastDate) &&
          new Date(item.startDate) <= new Date(this.toPastDate)
        );
      }
    }
    this.filteredPastTasks = filteredItems;
  }

  tableByPastDates() {

    [this.fromPastDate,this.toPastDate] = this.constructDatesBasedOnType(this.fromPastDateExample,this.toPastDateExample);

    if(this.selectedPastDateFilter!=4){
      this.selectedPastDateFilter=4;
    }

    this.pastTableByFiltersId();
  }

  tableByDates() {

    [this.fromDate,this.toDate] = this.constructDatesBasedOnType(this.fromDateExample,this.toDateExample);

    if(this.selectedDateFilter!=4){
      this.selectedDateFilter=4;
    }

    this.tableByFiltersId();
  }

  constructDatesBasedOnType(fromDateExample:any,toDateExample:any){
    var datePipe = new DatePipe("en-US");
    var tempDate = null;
    if (fromDateExample instanceof NgbDate) {
      tempDate = { day: fromDateExample.day, month: fromDateExample.month - 1, year: fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    var fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (toDateExample == null)
      toDateExample = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (toDateExample instanceof NgbDate) {

      tempToDate = { day: toDateExample.day, month: toDateExample.month - 1, year: toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    var toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);
  
    return [fromDate,toDate]
  }


  tableByFiltersId() {
    let filterFunction = (filteredItems: any) => {
      if (this.selectedFrequencies.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedFrequencies.includes(item.frequencyTypeId));
      }

      if(this.userRole=='Admin'){
        if (this.selectedUsers.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
        }
      }

      if (this.selectedTaskCategoryFilter.length > 0) {
        filteredItems = filteredItems.filter(item =>
            item.taskCategoryList.some(categoryId => this.selectedTaskCategoryFilter.includes(categoryId))
        );
      }

      if(this.selectedDateFilter == 1){
        this.considerDateFilter = false;
        this.fromDateExample = null;
        this.toDateExample = null;
        this.toDate = null;
        this.fromDate = null;
      }
      if(this.selectedDateFilter==4){
        this.considerDateFilter = true;
        [this.fromDate,this.toDate] = this.constructDatesBasedOnType(this.fromDateExample,this.toDateExample);
      }
      if(this.selectedDateFilter == 2 || this.selectedDateFilter == 3 ){
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
        var datePipe = new DatePipe("en-US");
        this.considerDateFilter = false;
        if (this.selectedDateFilter == 2) { // For "This Month"
          this.fromDateExample = new Date(now.getFullYear(), now.getMonth(), 1);
          this.toDateExample = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Setting day 0 gives last day of the previous month
          
          this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
          this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');
          
          filteredItems = filteredItems.filter(item => {
            var itemStartDate = new Date(item.startDate);
        
            return (
              (itemStartDate.getMonth() === currentMonth && itemStartDate.getFullYear() === currentYear)
            );
          });
        }
        else{
          const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
          const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      
          this.fromDateExample = new Date(lastMonthYear, lastMonth, 1);
          this.toDateExample = new Date(lastMonthYear, lastMonth + 1, 0);
          
          this.toDateExample = datePipe.transform(this.toDateExample,'yyyy-MM-dd');
          this.fromDateExample = datePipe.transform(this.fromDateExample, 'yyyy-MM-dd');
          
          filteredItems = filteredItems.filter(item => {
            var itemStartDate = new Date(item.startDate);
        
            return (
              (itemStartDate.getMonth() === lastMonth && itemStartDate.getFullYear() === lastMonthYear)
            );
          });
  
        }
      }
  
      if(this.considerDateFilter == true){
        if (this.fromDate || this.toDate) {
          filteredItems = filteredItems.filter(item =>
            new Date(item.startDate) >= new Date(this.fromDate) &&
            new Date(item.startDate) <= new Date(this.toDate)
          );
        }
      }

      this.tasks = []
      this.inprogressTasks = []
      this.doneTasks = []
      filteredItems.forEach(element => {
        if (element.statusId == 1) this.tasks.push(element)
        if (element.statusId == 2) this.inprogressTasks.push(element)
        if (element.statusId == 4) this.doneTasks.push(element)
      });
    };

    if(this.userRole!='Admin'){
      this.taskService.getListDtoByUserId(this.userId).subscribe((res: any) => {
        filterFunction(res.data)
      })
    }
    else{
      this.taskService.getListDto().subscribe((res: any) => {
        filterFunction(res.data)
      })
    }

  }


  cardClass() {
    return {
      'employee-task': localStorage.getItem("backgroundColor")!='dark',
      'employee-task-dark': localStorage.getItem("backgroundColor")=='dark',
    };
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isPastHovered(date: NgbDate) {
    return (
      this.fromPastDateExample && !this.toPastDateExample && this.hoveredPastDate && date.after(this.fromPastDateExample) && date.before(this.hoveredPastDate)
    );
  }
  isPastInside(date: NgbDate) {
    return this.toPastDateExample && date.after(this.fromPastDateExample) && date.before(this.toPastDateExample);
  }

  isPastRange(date: NgbDate) {
    return (
      date.equals(this.fromPastDateExample) ||
      (this.toPastDateExample && date.equals(this.toPastDateExample)) ||
      this.isPastInside(date) ||
      this.isPastHovered(date)
    );
  }

  /**
   * Open Todo
   *
   * @param idRef
   */
  openTodo(idRef) {
    //this._todoService.setCurrentTodo(idRef);
    this.recurre = new Recurre();
    this.task = new Task();
    this.taskService.getDtoById(idRef).subscribe((item: any) => {
      this.task = item.data;
      this.isReccuren = !item.data.isReccuren
      this.taskStatusService.checkTaskInformation(this.task.id).subscribe((item: any) => {
        this.task.isCompleted = item.data;
        if (this.task.isReccuren) {
          this.recurreService.getRecurreByTaskId(idRef).subscribe((item: any) => {
            this.recurre = item.data;
          });
        }
      });
      this.isDataEmpty = false;
    }, (err) => {
      this.errorService.errorHandler(err);
    });
    //this._coreSidebarService.getSidebarRegistry('todo-sidebar-right').toggleOpen();
  }


  updateTodo() {
    this.task.updatedBy = this.userId;
    this.task.deletedBy = 0;
    this.task.statusName = "";
    this.updateTask();
    if (this.task.isReccuren && this.recurre.frequencyTypeId != null) {
      if(this.recurre.id==null){
        this.recurre = {
          id: 0,
          taskId: this.task.id,
          frequencyNumber: 0,
          frequencyTypeId: this.recurre.frequencyTypeId,
          startDate: new Date(),
          endDate: new Date(),
          nextDueDate: new Date(),
          isComplated: false,
          deletedBy: 0,
          createdBy: this.userId,
          updatedBy: 0,
        }
      
        this.recurreService.add(this.recurre).subscribe((item: any) => {

        });
      }
      else{
        this.updateRecurre();

      }

    }

    this.todo.dueDate = this.dueDateRef.flatpickrElement.nativeElement.children[0].value;



  }


  addTodo1() {
    if(this.userRole!="Admin"){
      this.task1.userId = this.userId;
    }

    var realStartDate = new Date(this.task1.startDate);
    var realEndDate = new Date(this.task1.endDate);
    realStartDate.setHours(realStartDate.getHours() - (realStartDate.getTimezoneOffset() / 60));
    realEndDate.setHours(realEndDate.getHours() - (realEndDate.getTimezoneOffset() / 60));

    this.task1.startDate = new Date(realStartDate);
    this.task1.endDate = new Date(realEndDate); 

    this.task1.statusName = ""
    this.task1.userId = this.task1.userId
    this.task1.createdBy = this.userId
    this.task1.updatedBy = 0
    this.task1.deletedBy = 0
    this.task1.statusId = 1
    this.task1.isImportant = false
    this.taskService.add(this.task1).subscribe((res: any) => {
      if (this.task1.isReccuren == true) {
        this.recurre1.taskId = res.data.id
        this.recurre1.frequencyNumber = 0
        this.recurreService.add(this.recurre1).subscribe((res: any) => {
        })
      }
      this.informationService.add("Görev başarıyla eklendi.");
    }, (err) => {
      this.errorService.errorHandler(err)
    }, () => {
      this.taskInProcess=false;
      setTimeout(() => {
        this.tableByFiltersId()
      },500)
    })

  }
  /**
   * Delete Todo
   */
  deleteTodo() {
    //this.todo.deleted = !this.todo.deleted;
    //this._todoService.updateCurrentTodo(this.todo);
    this.taskService.delete(this.task.id).subscribe((item: any) => {
      this.informationService.delete(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.tableByFiltersId();
      }, 500);
    });
  }


  updateTask() {
    if(this.userRole!='Admin'){
      this.task.userId = this.userId;
    }

    var realStartDate = new Date(this.task.startDate);
    var realEndDate = new Date(this.task.endDate);
    realStartDate.setHours(realStartDate.getHours() - (realStartDate.getTimezoneOffset() / 60));
    realEndDate.setHours(realEndDate.getHours() - (realEndDate.getTimezoneOffset() / 60));

    this.task.startDate = new Date(realStartDate);
    this.task.endDate = new Date(realEndDate); 

    this.taskService.update(this.task).subscribe((item: any) => {
      this.informationService.update(item.message);
      document.getElementById("updatemodalclose").click()
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      this.taskInProcess=false;
      setTimeout(() => {
        this.tableByFiltersId()
      }, 500);
    });
  }

  updateRecurre() {
    this.recurre.updatedBy = this.userId
    this.recurre.deletedBy = 0;
    this.recurreService.update(this.recurre).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.tableByFiltersId()
      }, 500);
    });
  }
  createNewTask(): void {
    this.task = new Task();
    this.task1 = new Task();
    this.task1.userId = this.userId;
    this.task.userId = this.userId;
    this.isDataEmpty = true;
    this.task.isImportant = false;
    this.task.isReccuren = false;
    this.task1.taskCategoryList = this.task1.taskCategoryList.concat(this.taskCategories[0].id);
    this.task1.isImportant = false;
    this.task1.isReccuren = false;
  }
  changeRecurreType() {
    this.task1.isReccuren = !this.task1.isReccuren;
  }
  changeImportantType() {
    this.task.isImportant = !this.task.isImportant;
  }
  getInitials(name: string) {
    const userName = name;
    const firstInitial = userName.charAt(0);
    const lastInitial = userName.lastIndexOf(' ') > -1
      ? userName.substring(userName.lastIndexOf(' ') + 1, userName.lastIndexOf(' ') + 2)
      : '';

    return firstInitial + lastInitial;
  }


  getTurkishMonth(dateString: string): string {
    const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    return months[monthIndex];
  }
}
