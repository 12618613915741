import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class DecodeService {

  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor() { }

  getUserId(): number{
    if(localStorage.getItem("adminToken")){
      let decode = this.jwtHelper.decodeToken(localStorage.getItem("adminToken"));
      var userId=Object.keys(decode).filter(p=>p.endsWith("/nameidentifier"))[0];
      return +decode[userId];  
    }
    else{
      return 0;
    }

  }

  getUserName(): string{
    if(localStorage.getItem("adminToken")){
      let decode = this.jwtHelper.decodeToken(localStorage.getItem("adminToken"));
      var name = Object.keys(decode).filter(p=> p.endsWith("/name"))[0];
      return decode[name];
    }
    else{
      return "";
    }

  }

  getProjectVersion(): string{
    if(localStorage.getItem("adminToken")){
      let decode = this.jwtHelper.decodeToken(localStorage.getItem("adminToken"));
      var name = Object.keys(decode).filter(p=> p.endsWith("/projectVersion"))[0];
      return decode[name];
    }
    else{
      return "";
    }

  }

  getRole():string{
    if(localStorage.getItem("adminToken")){
      let decode = this.jwtHelper.decodeToken(localStorage.getItem("adminToken"));
      var role = Object.keys(decode).filter(p=> p.endsWith("/role"))[0];
      return decode[role];
    }
    else{
      return "";
    }

  }


}
