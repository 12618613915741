import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Login } from '../model/login';
import { Token } from '../model/token';
import { InformationService } from 'app/evo/services/information.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token:Token=new Token();
  
  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient: HttpClient,private router: Router,private informationService:InformationService) { }

  isAuthenticate(){
    if (localStorage.getItem("adminToken")) {
      return true;
    }
    return false;
  }


  

  login(login: Login){
    let api = this.baseApiUrl + "Login";
    this.httpClient.post(api, login).subscribe((res: any)=>{
      this.token = res;
      localStorage.setItem("adminToken", this.token.token);
      this.router.navigate(['/home']);
      setTimeout(()=>{
        window.location.reload();

      },2)
    },(err)=>{
      this.informationService.warning("Kullanıcı adı veya şifre hatalı!");
    });
  }

  customerLogin(login: Login){
    let api = this.baseApiUrl + "CustomerLogin";
    this.httpClient.post(api, login).subscribe((res: any)=>{
      this.token = res;
      localStorage.setItem("adminToken", this.token.token);
      localStorage.setItem("info","Müşteri Girdi");
      this.router.navigate(['/home']);
      setTimeout(()=>{
        window.location.reload();

      },2)
    });
  }


  logout(){
    localStorage.clear();
    this.router.navigate(['/pages/authentication/login-v2']);
  }
  activeUser(id:number){
    return this.httpClient.get(this.baseApiUrl+"ActiveUser/"+id)
  }

  getCurrentSiteVersion():Observable<string> {
    return this.httpClient.get<string>(this.baseApiUrl+"GetCurrentSiteVersion")
  }
}
