import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import 'hammerjs';
import { NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule } from 'ngx-quill';

import { FileUploadModule } from 'ng2-file-upload';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AuthInterceptor } from './evo/login/interceptor/auth.interceptor';
import { EvoModule } from './evo/evo.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from './evo/components/layout/fake-db.service';
import { UserActiveInterceptor } from './evo/login/interceptor/userActive.interceptor';
import { ErrorHandlingInterceptor } from './evo/login/interceptor/error.interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


const appRoutes: Routes = [
  {
    path: 'cv-add',
    loadChildren: () => import('./evo/components/list/cv-pool-item/evo-cv-pool-item-add.module').then(m => m.EvoCvPoolItemAddModule)

  },
  {
    path: 'cv-add/:cvPoolItemId',
    loadChildren: () => import('./evo/components/list/cv-pool-item/evo-cv-pool-item-add.module').then(m => m.EvoCvPoolItemAddModule)

  },
  {
    path: 'home',
    loadChildren: () => import('./evo/evo.module').then(m => m.EvoModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }
 

];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    NgxDatatableModule,
    Ng2FlatpickrModule,
    NgSelectModule,
    NgApexchartsModule,
    PerfectScrollbarModule,
    EvoModule,

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    QuillModule.forRoot({
      formats: [
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block',
        'formula',
        'mention'
        // 'image'
        // 'video'
      ],
    }),

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide:HTTP_INTERCEPTORS, useClass: UserActiveInterceptor, multi:true},
    {provide:HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi:true}


  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
