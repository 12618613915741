<p-confirmDialog appendTo="body" [baseZIndex]="10000" #confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body" >
      <section  id="ngx-datatable-kitchen-sink">
        <core-card-snippet>
          <h4 class="card-title">Satınalma Talep Listesi</h4>
          <div class="row">
            <div class="firstRow mt-1">
                <div style="margin-left:9px;">
                    <div class="col-12">
                        <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>

                </div>
            </div>
            <div class="search mt-1">
                <div class="col-12">
                    <input type="text" class="form-control" name="filterText" placeholder="Satınalma Talebi Arayın.."
                        [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                </div>
            </div>

            <div class="filter mt-1">
                <div class="col-12" *ngIf="userRole=='Admin'">
                    <div class="form-group">
                        <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                            placeholder="Kullanıcı Seçiniz " bindLabel="fullName" bindValue="id"
                            (change)="filterTable()">
                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label">{{ item.firstName }} {{item.lastName}}</span>
                                <span class="ng-value-icon right" (click)="clear(item)"
                                    aria-hidden="true">×</span>
                            </ng-template>
                            <ng-template ng-header-tmp>
                            </ng-template>
                        </ng-select>
                        <br />
                    </div>
                </div>
            </div>
        </div>
          <div class="row  mb-2 mt-2">
            <div class="col-4 ml-1">
                  <button class="btn btn-primary btn-sm ml-1 mr-1" (click)="clear()" rippleEffect data-bs-toggle="modal" data-bs-target="#addPurchaseEnquiry"
                    title="Yeni Satınalma Talebi">
                    <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                  </button>
                  <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                    <i data-feather="file-text" class=""></i>
                  </button>
            </div>
            <div id="dateFilter" class="row col-8" style="display: flex;justify-content: end;">
                <form class="form-inline">
                    <ng-select [clearable]="false" name="selectMonthForFilter" [multiple]="false" [items]="dateFilter"
                    [(ngModel)]="selectedDateFilter" placeholder="Aya Göre Filtrele"
                    bindLabel="name" bindValue="id" (change)="filterTable()" style="min-width:150px;" class="mr-1">
                    </ng-select>
                    <div class="form-group">
                        <div class="input-group w-0 ">
                            <input name="datepicker" class="form-control" ngbDatepicker
                                #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                [dayTemplate]="rangeSelectionDP" outsideDays="hidden"
                                [startDate]="fromDateExample!" />
                            <ng-template #rangeSelectionDP let-date let-focused="focused">
                                <span class="custom-day" type="datetime" [class.focused]="focused"
                                    [class.range]="isRange(date)"
                                    [class.faded]="isHovered(date) || isInside(date)"
                                    (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                </span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <input #dpFromDate class="form-control" type="date" placeholder="dd-MM-yyyy"
                                name="dpFromDate"
                                [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample"
                                (ngModelChange)="fromDateExample = $event; tableByDates();" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather icon-calendar"
                                    (click)="datepicker.toggle()" type="button" rippleEffect></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group ml-sm-2 mr-1">
                        <div class="input-group">
                            <input #dpToDate class="form-control" type="date" placeholder="dd-MM-yyyy"
                                name="dpToDate"
                                [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample"
                                (ngModelChange)="toDateExample = $event; tableByDates();" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather icon-calendar"
                                    (click)="datepicker.toggle()" type="button" rippleEffect></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

          </div>
          <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
            <thead>
              <th>Satınalma Talep No</th>
              <th>Talep Sahibi</th>
              <th>Açıklama</th>
              <th>Talep Tarihi</th>
              <th>Satınalma Son Tarihi</th>

            </thead>
            <tbody>
              <tr *ngFor="let item of purchaseEnquiryList">
                <td>{{item.purchaseOrderNo}}</td>
                <td>{{item.requesterName}}</td>
                <td>{{item.description}}</td>
                <td>{{item.purchaseDate | date:'dd.MM.yyyy HH:mm'}}</td>
                <td>{{item.purchaseDeadline | date:'dd.MM.yyyy HH:mm'}}</td>

              </tr>
            </tbody>
          </table>
          <ngx-datatable [rows]="filteredPurchaseEnquiryList |filter:filterText" [rowHeight]="58" class="bootstrap core-bootstrap"
            [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
            <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                        <div ngbDropdown container="body">
                            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                            <a ngbDropdownItem routerLink="/home/purchase-detail/{{row.id}}">
                                <i data-feather="search" class="mr-50"></i><span>Detaylar</span>
                            </a>
                            <button ngbDropdownItem (click)="get(row.id);delete();">
                                <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                            </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Satınalma Talep No" prop="purchaseOrderNo" [width]="200">
                <ng-template  let-row="row" let-name="value" ngx-datatable-cell-template>
                    {{row.purchaseOrderNo}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Talep Sahibi" prop="requesterName" [width]="200">
                <ng-template  let-row="row" let-name="value" ngx-datatable-cell-template>
                        {{row.requesterName}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Açıklama" prop="description" [width]="200">
                <ng-template  let-row="row" let-name="value" ngx-datatable-cell-template>
                    {{row.description.length > 30 ? row.description.slice(0, 30) + '...' : row.description}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Talep Tarihi" prop="purchaseDate" [width]="200">
                <ng-template  let-row="row" let-name="value" ngx-datatable-cell-template>
                        {{row.purchaseDate | date:"dd.MM.yyyy HH:mm:ss"}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Satınalma Son Tarihi" prop="purchaseDeadline" [width]="200">
                <ng-template  let-row="row" let-name="value" ngx-datatable-cell-template>
                        {{row.purchaseDeadline | date:"dd.MM.yyyy HH:mm:ss"}}
                </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

        </core-card-snippet>

      </section>
    </div>
  </div>

  <div class="modal fade" id="addPurchaseEnquiry" tabindex="-1" aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Satınalma Talebi Ekle</h1>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <form class="form form-vertical" #purchaseEnquiryForm="ngForm">
        <div class="modal-body">
            <div class="form-group">
                <div *ngIf="userRole=='Admin'">
                    <div class="row mb-2 pl-1 pr-1">
                        <label for="usrSelect">Kullanıcı Seç</label>
                        <select class="form-control" ngModel required name="slcUser" [(ngModel)]="purchaseEnquiry.requesterId"
                        #slcUser="ngModel">
                        <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}} {{item.lastName}}</option>
                        </select>
                        <small class="text-danger" *ngIf="slcUser.invalid&& slcUser.touched">Bir Kullanıcı
                        seçmeniz gerekiyor!</small>
                    </div>
                </div>
                <div class="row mb-2 pl-1 pr-1">
                    <label for="usrSelect">Açıklama</label>
                    <textarea class="form-control" ngModel required name="txtDescription" [(ngModel)]="purchaseEnquiry.description"
                    #txtDescription="ngModel"></textarea>
                    <small class="text-danger" *ngIf="txtDescription.invalid&& txtDescription.touched">Bir Kullanıcı
                        seçmeniz gerekiyor!</small>
                </div>
                <div class="row mb-2 pl-1 pr-1">
                    <label for="name-vertical">Satınalma Talep Tarihi</label>
                    <input type="datetime-local" name="txtPurchaseDate" #txtPaymentDate
                        class="form-control" [ngModel]="purchaseEnquiry.purchaseDate | date:'yyyy-MM-ddTHH:mm'"
                        (ngModelChange)="purchaseEnquiry.purchaseDate = $event" ngModel required #txtPurchaseDate="ngModel">
                    <small class="text-danger"
                        *ngIf="txtPurchaseDate.invalid&& txtPurchaseDate.touched">Tarih kısmı boş
                        bırakılamaz!
                    </small>
                </div>
                <div class="row mb-1 pl-1 pr-1">
                    <label for="name-vertical">Satınalma Son Tarihi</label>
                    <input type="datetime-local" name="txtPurchaseDeadline" #txtPaymentDeadline
                        class="form-control" [ngModel]="purchaseEnquiry.purchaseDeadline | date:'yyyy-MM-ddTHH:mm'"
                        (ngModelChange)="purchaseEnquiry.purchaseDeadline = $event" ngModel required #txtPurchaseDeadline="ngModel">
                    <small class="text-danger"
                        *ngIf="txtPurchaseDeadline.invalid&& txtPurchaseDeadline.touched">Tarih kısmı boş
                        bırakılamaz!
                    </small>
                </div>

                <div style="margin: 50px 0px 20px 0px;">
                    <fieldset class="form-group">
                        <label for="file-upload-multiple">Dosyalar</label>
                        <div class="custom-file">
                            <input class="custom-file-input" type="file"
                                (change)="getDocuments($event)" #documents
                                id="file-upload-multiple" ng2FileSelect [uploader]="uploader"
                                multiple />
                            <label class="custom-file-label" for="file-upload-multiple">Dosya
                                Seçiniz</label>
                        </div>
                    </fieldset>
                </div>

                <div class="col-lg-12 overflow-auto">
                    <div class="d-flex justify-content-between mb-1">
                        <p>Seçilen Dosya Sayısı: {{ uploader?.queue?.length }}</p>
                    </div>

                    <table class="table">
                        <thead>
                            <tr>
                                <th>Adı</th>
                                <th>Boyutu</th>
                                <th>İşlemler</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of uploader.queue">
                                <td>
                                    <strong>{{ item?.file?.name }}</strong>
                                </td>
                                <td *ngIf="uploader.options.isHTML5" nowrap>
                                    {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                                </td>
                                <td nowrap>
                                    <button type="button"
                                        class="btn btn-raised btn-outline-success btn-sm mr-1"
                                        (click)="downloadImage(item.file.name)" rippleEffect>
                                        <span data-feather="upload"></span>
                                    </button>
                                    <button type="button"
                                        class="btn btn-raised btn-outline-primary btn-sm mr-1"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showpriviewimage"
                                        (click)="previewImage(item)" rippleEffect>
                                        <i data-feather="eye"></i>
                                    </button>

                                    <button type="button"
                                        class="btn btn-raised btn-outline-danger btn-sm "
                                        (click)="item.remove()" rippleEffect>
                                        <i data-feather="trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                    <div id="">
                        <p class="mt-1">Dosyanın Aşaması:</p>
                        <ngb-progressbar class="mb-1" type="primary"
                            [value]="uploader.progress"></ngb-progressbar>
                        <button type="button" class="btn btn-outline-danger mb-1 mb-sm-0"
                            (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length"
                            rippleEffect>
                            <span data-feather="trash" class="mr-25"></span>Hepsini Sil
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="processPending=true;savePurchaseEnquiry()" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
            [disabled]="!purchaseEnquiryForm.valid || processPending==true">Kaydet</button>
        </div>
     </form>      
    </div>
  </div>
</div>