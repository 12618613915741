import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Competence } from 'app/evo/models/competence';
import { CompetenceModules } from 'app/evo/models/competencemodules';
import { Module } from 'app/evo/models/module';
import { Server } from 'app/evo/models/server';
import { ServerStatus } from 'app/evo/models/server-status';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoCompetenceService } from 'app/evo/services/evo-competence.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoServerControlService } from 'app/evo/services/evo-server-control.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { TabView } from 'primeng/tabview';

const themes = {
  light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
  dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
};

@Component({
  selector: 'app-evo-server-control',
  templateUrl: './evo-server-control.component.html',
  styleUrls: ['./evo-server-control.component.scss'
  ],
  providers:[ConfirmationService]
})
export class EvoServerControlComponent implements OnInit {

  constructor(
    private userService: EvoUserService,
    private serverControlService:EvoServerControlService,
    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private confirmationService:ConfirmationService,
    private router: Router,
    private cd:ChangeDetectorRef,

  ) { }
  myId: number;
  userRole: string = "";
  filterText: string = "";
  public basicSelectedOption: number = 100;

  users: User[] = [];
  modules: any[] = [];
  filteredModules: any[] = [];

  serverList:Server[]
  serverStatusList:ServerStatus[]

  filteredServerList:Server[]
  filteredServerStatusList:ServerStatus[]

  server:Server = new Server();

  serverListSize:number = 0;
  serverStatusListSize:number=0;

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;

  action:string="Ekle";

  currentPage = 1;
  pageSize = 100;
  
  allRowCounts:any[]=[{id:10,label:"10"},{id:25,label:"25"},{id:50,label:"50"},{id:100,label:"100"}]

  currentServerStatusPage = 1;
  activeIndex:number=0;

  loading:boolean=false;
  backgroundColor:string="default";

  @ViewChild('tabView', { static: false }) tabView: TabView;


  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  pageChange(page:number){
    this.currentPage = page;
  }

  serverPageChange(page:number){
    this.currentServerStatusPage = page;

  }

  ngOnInit(): void {
    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();
    
    this.userRole = this.decodeService.getRole();

    if(this.userRole!="Admin"){
      this.informationService.warning("Bu sayfayı görmek için yetkiniz bulunmamaktadır.")
      this.router.navigate(['/home']);

    }


    this.list();

  }

  list() {
      this.serverControlService.getList().subscribe((items: any) => {
        this.serverList = items.data;
        this.setActiveTab()
        this.serverListSize = this.serverList.length;
        this.tableByFiltersId();
        this.informationService.list(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      });

      this.serverControlService.getServerStatusList().subscribe((items: any) => {
        this.serverStatusList = items.data;
        this.setActiveTab();
        this.serverStatusListSize = this.serverStatusList.length;
        this.tableByFiltersId();
        this.informationService.list(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      });

  }

  handleSelectionChange(event?: any) {
    setTimeout(() => { // Ensure the DOM is updated
      const selectedItems = document.querySelectorAll('.p-multiselect-token .p-multiselect-token-label');
      selectedItems.forEach((item: HTMLElement) => {
        const text = item.textContent;
        if (text.length > 10) {
          const trimmedText = text.substring(0, 10) + '...';
          item.textContent = trimmedText;
        }
      });
    }, 0);
  }


  setActiveTab(){
    if(this.activeIndex == 0){
      this.filteredServerList = [];
      this.filteredServerList.push(undefined);
      setTimeout(()=>{
        this.filteredServerList = this.serverList;
      },1)
    }
    else{
      this.filteredServerStatusList = [];
      this.filteredServerStatusList.push(undefined);
      setTimeout(()=>{
        this.filteredServerStatusList = this.serverStatusList;
      },1)
    }

  }

  get(id: number) {
    this.serverControlService.get(id).subscribe((item: any) => {
      this.server = item.data;
    });
  }

  clear(){
    this.server = new Server();
  }

  saveServer(){

    if(this.checkValidations()==true){
      if(this.server.id==0){
        this.addServer(this.server);
      }
      else{
        this.confirmationService.confirm({
            message: 'Bu sunucuyu değiştirmek istediğinize emin misiniz?',
            header: 'Sunucu Değişimi',
            acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
            rejectButtonStyleClass: 'btn btn-secondary mr-2',
            acceptLabel: 'Evet',
            rejectLabel: 'Hayır',
            accept: () => {
            this.updateServer(this.server);
            },
            reject: (type: ConfirmEventType) => {
            switch(type) {
                case ConfirmEventType.REJECT:
                break;
                case ConfirmEventType.CANCEL:
                break;      
            }
            }
        });
      }
    }

  }

  addServer(item:any){

    this.serverControlService.add(item).subscribe((items: any) => {

      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 150);
    });

  }

  updateServer(item:any){

    this.serverControlService.update(item).subscribe((items: any) => {

      this.informationService.update(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 150);
    });

  }

  
  checkServerStatusNow(){
    this.loading=true;
    this.serverControlService.checkServerStatusNow().subscribe((res:any)=>{
      this.informationService.update("Sunucu kontrolü tamamlandı.");
      this.loading=false;

    },(err)=>{
      this.loading=false;
      setTimeout(() => {
        this.list();
      }, 150);
    },()=>{
      this.loading=false;
      setTimeout(() => {
        this.list();
      }, 150);
    })
  }

  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  delete(id: number) {

    this.confirmationService.confirm({
      message: 'Bu sunucuyu silmek istediğinize emin misiniz?',
      header: 'Sunucu Silme',
      acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
      rejectButtonStyleClass: 'btn btn-secondary mr-2',
      acceptLabel: 'Evet',
      rejectLabel: 'Hayır',
      accept: () => {
        this.serverControlService.delete(id).subscribe((items: any) => {
          this.informationService.delete(items.message);
        }, (err) => {
          this.errorService.errorHandler(err);
        }, () => {
          setTimeout(() => {
            this.list();
          }, 150);
        });
      },
      reject: (type: ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;      
        }
      }
    });

  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Sunucular";
    this.helperService.exportExcel(element, title);
  }

  tableByFiltersId() {
    
  }

  checkValidations() :boolean{
    var serverStatusValidation=true;
    const hasValidProtocol = this.server.address.startsWith("http://") || this.server.address.startsWith("https://");
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/.*)?$/;

    if (!urlPattern.test(this.server.address)) {
      this.informationService.warning('Lütfen internet adresi formatına uygun şekilde düzenleyiniz.');
      serverStatusValidation = false;

      if(!hasValidProtocol){
        this.informationService.warning("Web adresleri ve API bağlantıları http:// veya https:// ile başlamalıdır.")
      }

    }



    return serverStatusValidation
  }


  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
}

listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      console.log('Background color changed:', backgroundColor);
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cd.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }
}
