import { CvPoolItemEducationInfo } from "./cv-pool-item-education-info";
import { CvPoolItemEmergencyCallNumber } from "./cv-pool-item-emergency-call-number";
import { CvPoolItemFile } from "./cv-pool-item-file";
import { CvPoolItemInternshipInfo } from "./cv-pool-item-internship-info";
import { CvPoolItemLanguageProficiency } from "./cv-pool-item-language-proficiency";
import { CvPoolItemPreviousExperience } from "./cv-pool-item-previous-experience";
import { CvPoolItemProject } from "./cv-pool-item-project";
import { CvPoolItemReference } from "./cv-pool-item-reference";
import { CvPoolItemSkill } from "./cv-pool-item-skill";

export class CvPoolItem {
    id:number=0;
    guid:string;
    fullName:string;
    identificationNumber:string;
    gsmNo:string;
    email:string;
    birthDate:Date;
    nationalityId:number;
    genderId:number;
    enlistmentStatus:number|null=null;
    address:string;
    cityId:number;
    districtId:number;
    startDate?:Date;
    workplaceRecommendation:string|null=null;
    workplacePositionId:number;
    workplacePositionName:string;
    applierTypeId:number;
    applierType:string;
    cvPoolItemEducationInfoList:CvPoolItemEducationInfo[]=[];
    cvPoolItemEmergencyCallNumberList:CvPoolItemEmergencyCallNumber[]=[];
    cvPoolItemFileList:CvPoolItemFile[]=[];
    cvPoolItemInternshipInfoList:CvPoolItemInternshipInfo[]=[];
    cvPoolItemLanguageProficiencyList:CvPoolItemLanguageProficiency[]=[];
    cvPoolItemPreviousExperienceList:CvPoolItemPreviousExperience[]=[];
    cvPoolItemProjectList:CvPoolItemProject[]=[];
    cvPoolItemReferenceList:CvPoolItemReference[]=[];
    cvPoolItemSkillList:CvPoolItemSkill[]=[];
    documents:any[]=[]
}