import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Partner } from 'app/evo/models/partner';
import { PartnerUser } from 'app/evo/models/partneruser';
import { Gender } from 'app/evo/models/gender';
import { Project } from 'app/evo/models/project';
import { Role } from 'app/evo/models/role';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { ProjectStatus } from 'app/evo/models/projectstatus';
import { first } from 'rxjs/operators';
import { title } from 'process';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

const themes = {
  light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
  dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
};


@Component({
  selector: 'app-evo-customer-update-form',
  templateUrl: './evo-customer-update-form.component.html',
  styles: [
  ]
})
export class EvoPartnerUpdateFormComponent implements OnInit,OnDestroy {

  partner: Partner = new Partner();
  user: User = new User();
  partnerUser: PartnerUser = new PartnerUser();
  gender: Gender = new Gender();
  project: Project = new Project();
  role: Role = new Role();
  projectStatus: ProjectStatus = new ProjectStatus();

  partners: Partner[] = [];
  users: User[] = [];
  partnerUsers: PartnerUser[] = [];
  filteredPartnerUsers:PartnerUser[]=[];
  projects: Project[] = [];
  genders: Gender[] = [
    { id: 1, name: "Erkek" },
    { id: 2, name: "Kadın" },
  ];
  roles: Role[] = [
    { id: 1, name: "Müşteri Yönetici" },
    { id: 2, name: "Müşteri Kullanıcı" }
  ];
  projectStatuses: ProjectStatus[] = [
    { id: 1, name: "Devam Ediyor" },
    { id: 2, name: "Tamamlandı" }
  ];

  public passwordTextTypeNew = false ;

  deletedPartnerUserId: number;
  deletedProjectId: number;

  constructor(
    private partnerService: EvoPartnerService,
    private partnerUserService: EvoPartnerUserService,
    private projectService: EvoProjectService,
    private userService: EvoUserService,
    private transactionService: EvoTransactionService,

    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private decodeService: DecodeService,
    private router: Router,
    private informationService: InformationService,
    private errorService: ErrorService,
    private cd:ChangeDetectorRef
  ) { }

  addPartnerUserForm: FormGroup;

  id: number;
  myId: number;
  userRole: string;

  externalSources: User[] = [];
  filteredExternalSources:User[]=[];

  internalSources: User[] = [];
  supplierInfo: boolean = false;

  externalSource: User = new User();
  selectedExternalUser: User = new User();
  selectedInternalUser: User = new User();

  selectedInternalUserId: number = 0;
  deletedExternalUserId: number = 0;


  consultantActivityFilters : any[]=[
    {label:'Aktif',value:"Aktif"},{label:"Pasif",value:"Pasif"}]

  selectedConsultantActivityFilter:any[]=[{label:'Aktif',value:"Aktif"}];

  externalActivityFilters : any[]=[
    {label:'Aktif',value:"Aktif"},{label:"Pasif",value:"Pasif"}]

  selectedExternalActivityFilter:any[]=[{label:'Aktif',value:"Aktif"}];

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;

  backgroundColor:string="default";

  ngOnInit(): void {
    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();
    this.myId = this.decodeService.getUserId();
    this.id = this.route.snapshot.params['Id'];
    this.userRole = this.decodeService.getRole();
    if (this.userRole != "Admin" && this.userRole != "Customer") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(this.id, 8, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list();
      this.getMyExternalSources();
    }
    this.addPartnerUserForm = this.formBuilder.group({
      txtUserFirstName: ['', Validators.required],
      txtUserLastName: ['', Validators.required],
      txtUserTile: ['', Validators.required],
      txtUserMailAdress: ['', [Validators.required, Validators.email]],
      txtUserPhoneNumber: ['', Validators.required],
      slcGender: ['', Validators.required],
      slcRole: ['', Validators.required],
      txtUserName: ['', Validators.required],
      txtUserPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)]],
    });
  }

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  list() {
    this.partnerService.get(this.id).subscribe((res: any) => {
      this.informationService.get("Cari Bilgisi Başarılı Bir Şekilde Getirildi.");
      this.partner = res.data;
      this.supplierInfo = this.partner.isSupplier;
    });
    this.projectService.getListByPartnerId(this.id).subscribe((res: any) => {
      this.projects = res.data;
    })
    this.partnerUserService.gePartnerUsertListByPartnerId(this.id).subscribe((res: any) => {
      this.partnerUsers = res.data;
      this.filterPartnerUsers();
    });
    this.userService.getList().subscribe((res: any) => {
      this.users = res.data;
    });
  }

  update() {
    if (!this.partner.isSupplier && this.supplierInfo && !this.partner.isSupplier) {
      this.deleteAllExternalSources();
    }

    this.partner.updatedBy = this.myId;
    this.partner.deletedBy = 0;

    this.partnerService.update(this.partner).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 1000);
    })
  }
  deleteAllExternalSources() {
    for (let i = 0; i < this.externalSources.length; i++) {
      this.deleteExternalUser(this.externalSources[i].id);
    }
  }

  changeUserActivityStatus(id:any){
    this.partnerUserService.get(id).subscribe((res: any) => {
      var partnerUser = res.data;
      partnerUser.password = "";
      this.partnerUserService.updateActivityStatus(partnerUser).subscribe((res2: any) => {
        this.informationService.update(res2.message);
  
      },(err)=>{
        this.errorService.errorHandler(err);
      },()=>{
        setTimeout(() => {
          this.ngOnInit();
        }, 1000);
      });
    });


  }

  filterExternalUsers(){
    if(this.selectedExternalActivityFilter.length==2 || this.selectedExternalActivityFilter.length==0){
      this.filteredExternalSources = this.externalSources;
    }
    else if(this.selectedExternalActivityFilter.length==1 ){
        if(this.selectedExternalActivityFilter[0].value=="Aktif"){
          this.filteredExternalSources = this.externalSources.filter(x=>x.isActive==true)
        }
        else{
          this.filteredExternalSources = this.externalSources.filter(x=>x.isActive==false)

        }
    }
  }

  filterPartnerUsers(){
      if(this.selectedConsultantActivityFilter.length==2 || this.selectedConsultantActivityFilter.length==0){
        this.filteredPartnerUsers = this.partnerUsers;
      }
      else if(this.selectedConsultantActivityFilter.length==1 ){
          if(this.selectedConsultantActivityFilter[0].value=="Aktif"){
            this.filteredPartnerUsers = this.partnerUsers.filter(x=>x.isActive==true)
          }
          else{
            this.filteredPartnerUsers = this.partnerUsers.filter(x=>x.isActive==false)

          }
      }
  }
  changePartnerCustomer() {
    if (this.partner.isCustomer == true) {
      this.partner.isCustomer = false;
    } else {
      this.partner.isCustomer = true;
    }
  }
  changePartnerSupplier() {

    if (this.partner.isSupplier == true) {
      this.partner.isSupplier = false;
    } else {
      this.partner.isSupplier = true;
    }
  }
  getMyExternalSources() {
    this.partnerUserService.getMyExternalSources(this.id).subscribe((res: any) => {
      this.externalSources = res.data;
      this.filterExternalUsers();
    })
    this.getInternalSourceUsers();
  }
  getInternalSourceUsers() {
    this.userService.getInternalSourceUsers().subscribe((res: any) => {
      this.internalSources = res.data;
    }
      , err => {
        this.errorService.errorHandler(err);
      })
  }
  getExternalUserById(id: number) {
    this.userService.get(id).subscribe((res: any) => {
      this.externalSource = res.data;
    })
  }

  addExternalUser(id: number) {
    this.userService.get(id).subscribe((res: any) => {
      this.selectedInternalUser = res.data;
      this.selectedInternalUser.partnerId = Number(this.id);
      this.selectedInternalUser.typeId = 2;
      this.selectedInternalUser = {
        id: this.selectedInternalUser.id,
        partnerId: this.selectedInternalUser.partnerId,
        typeId: this.selectedInternalUser.typeId,
        firstName: "",
        lastName: "",
        mailAddress: "",
        password: "",
        phoneNumber: "",
        userName: "",
        title: "",
        roleId: 0,
        genderId: 0,
        imageURL: "",
        vacationCount:0,
        isActive:true,
        partnerName: "",
        createdBy: 0,
        updatedBy: this.myId,
        deletedBy: 0,
      };

      this.userService.updateType(this.selectedInternalUser).subscribe((res: any) => {
        this.informationService.add("Dış Kaynak Kullanıcı Başarılı Bir Şekilde Eklendi.");
      }, err => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
          this.getInternalSourceUsers();
        }, 1000);
      })
    });
  }

  deleteExternalUser(id: number) {
    this.userService.get(id).subscribe((res: any) => {
      this.externalSource = res.data;
      this.externalSource = {
        id: id,
        partnerId: null,
        typeId: 1,
        firstName: "",
        lastName: "",
        mailAddress: "",
        password: "",
        phoneNumber: "",
        userName: "",
        title: "",
        roleId: 0,
        genderId: 0,
        imageURL: "",
        vacationCount:0,
        isActive:true,
        partnerName: "",
        createdBy: 0,
        updatedBy: this.myId,
        deletedBy: 0,
      };
      this.userService.updateType(this.externalSource).subscribe((res: any) => {
        this.informationService.delete("Dış Kaynak Kullanıcı Başarılı Bir Şekilde Silindi.");
      }, err => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
          this.getInternalSourceUsers();
        }, 1000);
      });
    });


  }

  addPartnerUser() {
    this.partnerUser={
      id:0,
      partnerId:this.partner.id,
      partnerName:this.partner.name,
      userId:0,
      userName:this.addPartnerUserForm.value.txtUserName,
      firstName:this.addPartnerUserForm.value.txtUserFirstName,
      lastName:this.addPartnerUserForm.value.txtUserLastName,
      mailAddress:this.addPartnerUserForm.value.txtUserMailAdress,
      phoneNumber:this.addPartnerUserForm.value.txtUserPhoneNumber,
      password:this.addPartnerUserForm.value.txtUserPassword,
      title:this.addPartnerUserForm.value.txtUserTile,
      genderId:this.addPartnerUserForm.value.slcGender,
      imageUrl:"",
      roleId:this.addPartnerUserForm.value.slcRole,
      isActive:true,
      createdBy:this.myId,
      updatedBy:0,
      deletedBy:0,
    }

    this.partnerUserService.add(this.partnerUser).subscribe((item: any) => {
      this.informationService.add("Kullanıcı Bilgisi Başarılı Bir Şekilde Eklendi");
    }, (err) => {
      this.errorService.errorHandler("İşlem Başarısız. Lütfen Giriş Bilgileriniz Gözden Geçirip Tekrar Deneyin");
    }, () => {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    })

  }
  addProject(title: string, description: string) {
    this.project = {
      id: 0,
      title: title,
      description: description,
      partnerId: this.partner.id,
      partnerName: "",
      statusId: 1,
      statusName: "",

      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    }
    this.projectService.add(this.project).subscribe((item: any) => {
      this.informationService.add(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
  }

  getProject(id: number) {
    this.projectService.get(id).subscribe((res: any) => {
      this.project = res.data;
    })
  }

  getPartnerUserById(id: number) {
    this.partnerUserService.get(id).subscribe((item: any) => {
      this.partnerUser = item.data;
    });
  }

  updatePartnerUser() {
    if(this.partnerUser.password==null){
      this.partnerUser.password="";
    }

    this.partnerUser.imageUrl = "";
    this.partnerUser.updatedBy = this.myId;
    this.partnerUser.deletedBy = 0;
    this.partnerUserService.update(this.partnerUser).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler("İşlem Başarısız.Lütfen Giriş Bilgileriniz Gözden Geçirip Tekrar Deneyin");
    }, () => {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
  }

  updateProject() {
    this.project.updatedBy = this.myId;
    this.project.deletedBy = 0;
    this.projectService.update(this.project).subscribe((res: any) => {
      this.informationService.update(res.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    });
  }
  deletePartnerUser() {
    this.partnerUserService.get(this.deletedPartnerUserId).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;
      item.data.password = "";
      this.partnerUserService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
    });
  }

  deleteProject() {
    this.projectService.get(this.deletedProjectId).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;

      this.projectService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
    });
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Müşteri Kullanıcıları";
    this.helperService.exportExcel(element, title);
  }
  exportExcelProject() {
    let element = document.getElementById("excel-table-project");
    let title = "Projeler";
    this.helperService.exportExcel(element, title);
  }
  exportExcelExternalUsers() {
    let element = document.getElementById("excel-externaluser-table");
    let title = "Dış Kaynak Kullanıcıları";
    this.helperService.exportExcel(element, title);
  }

  togglePasswordTextTypeNew() {
    this.passwordTextTypeNew = !this.passwordTextTypeNew;
  }

  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }


  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
}

listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cd.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }
}
