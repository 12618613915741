export class PartnerUser{
    id:number;
    partnerId:number;
    partnerName:string;
    userId:number;
    firstName:string;
    lastName:string;
    title:string;
    mailAddress:string;
    phoneNumber:string;
    genderId:number;
    imageUrl:string;
    userName:string;
    password:string;
    roleId:number;
    isActive:boolean;
    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}